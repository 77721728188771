// Rating
// --------------------------------------------------
.rating {
	list-style: none;
	padding: 0;
	margin: 0;

	li {
		position: relative;
		display: inline-block;
		color: $black;
		font-size: 14px;
		text-align: center;
		width: 14px;
		height: 14px;

		&:not(:last-child) {
			margin: 0 5px 0 0;
		}

		&:before,
		&:after {
			@include icons;
			position: absolute;
			text-align: center;
			left: 0;
		}

		&:before {
			content: "\E00C";
			z-index: 5;
		}
	}

	li.rating__empty {
		color: #dedede;
	}

	li.rating__half {
		color: #dedede;

		&:before {
			content: "\E00D";
			color: $black;
		}
		
		&:after {
			content: "\E00C";
			z-index: 1;
			color: #dedede;
		}
	}

	&[class$="rating"] &__empty {

		display: none;

	}

	&--price {
		li {
			&:before {
				content: "\E00E";
			}
		}
	}
}
// Standard content region
// --------------------------------------------------

.winner-profile .is-editable,
.restaurant-profile .is-editable,
.news-article .is-editable,
.page-content .is-editable {
	@include clearfix;

	strong {
		@include bold;
	}

	h2,
	h3,
	h4,
	h5,
	h6 {
		margin-bottom: 20px;
	}

	a:not[data-group] {
		color: #646465 !important;
		transition: border 200ms, color 200ms !important;
        border-bottom: 1px solid #646465 !important;

        @include hover {
			border-color: transparent !important;
			color: $blue !important;
        }
	}

	p + h2,
	p + h3,
	p + h4,
	p + h5,
	p + h6 {
		margin-top: 30px;
	}

	ul {
		margin: 30px 0;
		padding: 0;
	}

	li {
		margin: 0 0 10px 20px;
		padding-left: 20px;
	}

	img {
		max-width: 100%;
		height: auto;
	}

	blockquote {
		@include fluid(font-size, 700px, 1500px, 16px, 18px);
		@include karla;
		@include bold;
		margin: 40px 0;
		padding: 0 0 0 40px;
		max-width: 650px;
		border-left: 5px solid #000;
	}

	.iframe-wrapper {
		position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		padding-top: 25px;
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
}

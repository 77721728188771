// Social Share
// --------------------------------------------------

.social-links {
    @include clearfix;
    
    ul {
        @include clearfix;
	    margin: 0;
	    padding: 0;
	    list-style: none;
        display: flex;
        flex-wrap: wrap;
        gap: 1em;

        > li {

            width: 20px;
            height: 20px;

        }

    }  

    span {
        @include hide;
    }

    a {
        position: relative;
        width: 1em;
        height: 1em;
        color: #4f4e50;            

        &:before {
            @include icons;
            position: absolute;
            font-size: 1em;
            left: 0;
            top: 0;
            transition: color 200ms;
        }

        svg {

            max-width: 16px;

            path {

                fill: currentColor;

            }

        }
        
        @include hover {
	        &:before {
		       color: #fff;
	        }
        }
    }

    &__youtube {
	    &:before {
            content: "\E002";
        }
    }

    &__facebook {
	    &:before {
	        content: "\E004";
        }
    }
       
    &__instagram {
	    &:before {
	        content: "\E005";
        }
    }

    &__twitter {
	    &:before {
	        content: "\E00F";
        }
    }

    &__mail {
	    &:before {
	        content: "\E010";
        }
    }

}
// Search
// --------------------------------------------------
.search {
	@include center;
	//@include bold;
	@include karla;
	@include fluid(font-size, 700px, 1500px, 18px, 30px);
	position: fixed;
	left: 0;
	top: 0;
	height: 125px;
	color: #fff;
	width: 100%;
	z-index: 100;
	background-color: #030204;
	transform: translateY(-125px);
	transition: transform 600ms cubic-bezier(0.165, 0.84, 0.44, 1);

	&:before {
		@include icons;
		position: absolute;
		left: 25px;
		top: calc(50% - 10px);
		font-size: 20px;
		content: "\E001";
		color: #fff;
		z-index: 10;
	}

	.link--close {
		position: absolute;
		width: 20px;
		height: 20px;
		right: 30px;
		top: 54px;
		z-index: 10;
		padding: 0;
		color: #fff;
		transition: color 200ms;

		&:before {
			@include icons;
			content: "\E008";
			font-size: 20px;
		}

		@include hover {
			color: rgba(#fff, 0.5);
		}
	}
	
	.search-open & {
		transform: translateY(0);
	}

	.search__field {
		position: relative;
	}

	label:not(.error) {
		@include hide;
	}
	
	input {
		@include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 5px, $size: 1px);
		background-color: #030204;
		border-radius: 0;
		border: 0;
		height: 125px;
		line-height: 1;
		padding: 0 30px 0 60px;
		transition: background 200ms;
		width: 100%;
		color: #fff;

		&:-webkit-autofill {
			-webkit-box-shadow: 0 0 0 1000px #030204 inset;
		}

		&:-webkit-autofill {
			-webkit-text-fill-color: yellow !important;
		}
		
		&:focus {
			//background: lighten($black, 10);
		}
		
		&::-webkit-input-placeholder {
			transition: color 200ms;
		    color: #fff;
		}
		
		&:-moz-placeholder {
		    transition: color 200ms;
		    color: #fff;
		}
		
		&::-moz-placeholder {
		    transition: color 200ms;
		    color: #fff;
		}
		
		&:-ms-input-placeholder {  
		    transition: color 200ms;
		    color: #fff;
		}
		
		&:focus::-webkit-input-placeholder { 
			color: rgba(#fff, 0.5); 
		}
		
		&:focus:-moz-placeholder { 
			color: rgba(#fff, 0.5); 
		}
		
		&:focus::-moz-placeholder { 
			color: rgba(#fff, 0.5);
		}
		
		&:focus:-ms-input-placeholder { 
			color: rgba(#fff, 0.5);
		}
		
	}

	@include breakpoint(0, 650px) {
		input {
			padding: 0 15px 0 55px;
		}
	}

}
// Page 50
// --------------------------------------------------
.page--50 {

    main.content {
        padding-top: 0 !important;
    }

    .panel--logo-gallery {
        border-top: 2px solid #000;
        border-bottom: 0;
        margin-bottom: 0 !important;
        margin-top: 85px;
    }

    &.page--50-grid {
        .panel--logo-gallery {
            margin-top: 160px;
        }
    }

    &.search-open .top50-nav.is--sticky {
        top: 205px;

        @include breakpoint(0, 680px) {
            top: 185px;
        }
    }
}

.page--50-grid {
    .top50-grid {
        @include flex-grid(4, 60px, 30px,'.top50-grid__item');
        min-height: 460px;

        .top50-grid__item {
            min-height: 425px;
        }
    }

    @include breakpoint(0, 1250px) {
       .top50-grid {
            @include flex-grid(3, 60px, 30px,'.top50-grid__item');
       }
    }

    @include breakpoint(0, 1100px) {
        .top50-grid {
            @include flex-grid(3, 30px, 30px,'.top50-grid__item');
        }
    }

    @include breakpoint(0, 850px) {
        .top50-grid {
            @include flex-grid(2, 30px, 30px,'.top50-grid__item');
        }
    }

    @include breakpoint(0, 500px) {
        .top50-grid {
            @include flex-grid(1, 0, 30px,'.top50-grid__item');
        }
    }
}

.top50-nav {
    //@include fluid(margin-bottom, 700px, 1500px, 50px, 80px);
    margin-bottom: 60px;
    @include font-size(16);
    @include karla;
    @include bold;
    background: #fff;
    z-index: 40;

    .container {
        position: relative;

        &:after {
            @include dottedBorder($color: #0d0d0d, $orientation: horizontal, $position: bottom, $spacing: 8px, $size: 2px);
            position: absolute;
            bottom: 0;
            left: 30px;
            height: 2px;
            width: calc(100% - 60px);
            content: '';
        }
    }

    .menu-region {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    ul {
        display: flex;
    }

    &__menu {
        li {
            margin: 0 50px 0 0;
        }
    
        a {
            position: relative;
            padding: 30px 0;
            display: inline-block;
            color: $black;
    
            &:before {
                @include center;
                position: absolute;
                bottom: 0;
                height: 2px;
                width: 0;
                transition: width 200ms ease-out;
                background: #34343a;
                content: '';
            }
    
            @include hover {
                &:before {
                    width: 100%;
                }
            }
            
            &.active:before {
                background: #34343a;
                width: 100%;
    
                @include hover {
                    &:before {
                        background: #34343a;
                        width: 100%;
                    }
                }
            }
        }
    }

    &__anchor {
        margin-left: auto;

        span {
            color: rgba($black, 0.5);
        }

        a {
            color: $black;
            padding: 30px 0;
            display: inline-block;
            
            &.js-is-active {
                color: $blue;

                span {
                    color: $blue;
                }
            }

            @include hover {
                color: $blue;
            }
        }

        li {
           
            &:first-child {
                span {
                    display: inline-block;
                    padding: 30px 0;
                }
            }
           
            &:not(:last-child) {
                margin: 0 40px 0 0;
            }
        }
    }

    .menu-trigger {
        padding: 30px 0;
        display: none;
        cursor: pointer;

        &:after {
            @include icons;
            font-size: 6px;
            content: "\E007";
            margin: 0 0 0 15px;
            transition: transform 200ms;
        }

        &.is--active {
            &:after {
                transform: rotate(180deg);
            }
        }
    }

    @include breakpoint(0, 900px) {
        &__anchor {
            li {
               &:not(:last-child) {
                    margin: 0 20px 0 0;
                }
            }
        }
    }

    @include breakpoint(0, 600px) {
        &__menu {
            margin-bottom: 20px;

            a {
                padding: 30px 0 10px;
            }
        }
    }

    @include breakpoint(0, 350px) {
        @include font-size(13);

        .menu-trigger {
            @include font-size(16);
        }
    }

    .page--50-story & {

        @include breakpoint(0, 600px) {
            
           .menu-trigger {
                display: block;
            }

            .menu-region {
                transition: all 400ms ease-out;
                max-height: 0;
                overflow: hidden;
                display: block;
                text-align: left;

                &.is--open {
                    max-height: 500px;
                }
            }

            li:not(:last-child) {
                margin: 0 15px 0 0;
            }
        }


    }

    .page--50-grid & {
        
        @include breakpoint(0, 1180px) {

            &__menu {
                li {
                    margin: 0 10px 0 0;
                }
            }

            &__anchor {
                li {
                    &:not(:last-child) {
                        margin: 0 20px 0 0;
                    }
                } 
            }
        }

        @include breakpoint(0, 850px) {

            &__anchor {
                li {
                    &:not(:last-child) {
                        margin: 0 10px 0 0;
                    }
                } 
            }
        }

        @include breakpoint(461px, 1180px) {
            .hide-mob {
                @include hide;
            }
        }

        @include breakpoint(0, 600px) {
            
           .menu-trigger {
                display: block;
            }

            .menu-region {
                transition: all 400ms ease-out;
                max-height: 0;
                overflow: hidden;
                display: block;
                text-align: left;

                &.is--open {
                    max-height: 500px;
                }
            }

            &__anchor {
                li:first-child {
                    display: none;
                }
            }

            li:not(:last-child) {
                margin: 0 15px 0 0;
            }
        }

        @include breakpoint(0, 460px) {
            &__anchor {
                display: block;
                
                li {
                    width: 100%;
    
                    a {
                        padding: 15px 0;
                    }

                    &:last-child {
                        a {
                            padding-bottom: 30px;
                        }
                    }
                }

            }
        }

    }

    &.is--sticky {
        @include center;
        position: fixed;
        top: 80px;
        width: 100%;
        transition: top 400ms;

        & + .container {
            padding-top: 142px;
        }

        @include breakpoint(0, 680px) {
            top: 60px;

            & + .container {
                padding-top: 140px;
            }
        }
    }
}

.page--50-story.header--show-alt .top50-nav,
.page--50-grid.header--show-alt .top50-nav {
    &.is--sticky {
        top: 140px;  
        
        & + .container {
            padding-top: 140px;
        }

        @include breakpoint(0, 680px) {
            top: 60px;
        } 
    }
}

.tablet .top50-nav.is--sticky {
    top: 140px;  
    
    & + .container {
        padding-top: 140px;
    }

    @include breakpoint(0, 680px) {
        top: 60px;

        & + .container {
            padding-top: 140px;
        }
    }
}


@include breakpoint(0, 900px) {
    .top50-nav.is--sticky {
        top: 140px;  
        
        & + .container {
            padding-top: 140px;
        }

        @include breakpoint(0, 680px) {
            top: 60px;
        }
    }
}

.has-dropmenu {

    > a {
        position: relative;
        z-index: 2;
    }
    .is-dropmenu {
        display: block;
        opacity: 0;
        pointer-events: none;
        transition: opacity $timing*1.5 $ease-std;
    }

    &.focussed,
    &:focus,
    &:hover {

        .is-dropmenu {
            opacity: 1;
            pointer-events: all;

            ul.megamenu li {
                transform: translateY(0);
                opacity: 1;
            }

            ul.megamenu li:nth-child(1) { transition-delay: .0s; }
            ul.megamenu li:nth-child(2) { transition-delay: .15s; }
            ul.megamenu li:nth-child(3) { transition-delay: .3s; }
            ul.megamenu li:nth-child(4) { transition-delay: .45s; }
            ul.megamenu li:nth-child(5) { transition-delay: .6s; }
        }
    }
}

.dropmenu--lists {

    display: none;
    position: absolute;
    left: 0;
    // top: 100%;
    top: 80%;
    z-index: 1;
    padding-top: 0px;

    @include breakpoint(901px,0) {
        // width: calc(200% + 180px);
        width: 100%;
    }
    @include breakpoint(0,900px) {
        width: calc(200% + 30px);
    }

    &:before {
        content: '';
        background: linear-gradient(to bottom, rgba(black,.4) 0%,rgba(black,0) 100%);
        height: 70px;
    }

    &:before,
    &:after {
        position: absolute;
        // left: -50vw;
        top: 0;
        z-index: 1;
        // width: 150vw;
        display: block;
        pointer-events: none;
    }

    &:after {
        background-color: black;
        height: calc(100% + 50px);
        border-top: 1px dotted #454546;

        // .page--internal &,
        // .header--sticky & {
        //     content: '';
        // }
    }

    @include breakpoint(0,1000px) {
        &:before,
        &:after {
            left: 0;
        }
    }
}
ul.megamenu {
    @include clearfix();
    @include list-reset();
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    box-shadow: 0 0 25px 2px rbga(black,.45);
    position: relative;
    z-index: 2;

    > li {
        @include reg();
        display: block;
        background-color: #141416;
        width: calc(33.3333% - 2px);
        margin-left: 1px;
        margin-right: 1px!important;

        opacity: 0;
        transform: translateY(-20px);

        transition: transform $timing*1.5 $ease-std,
                    opacity $timing*1.5 $ease-std;
    }
    &.count-2 > li {
        width: calc(50% - 2px);
    }
    &.count-3 > li {
        width: calc(33.3333% - 2px);
    }
    &.count-4 > li {
        width: calc(25% - 2px);
    }
    &.count-5 > li {
        width: calc(20% - 2px);
    }
    @include breakpoint(0, 1000px) {
        &.count-4,
        &.count-5 {
             .megamenu__item {
                 min-height: 250px;
             }
            .text {
                display: none;
            }
        }
        &.count-4 {
            > li {
                width: calc(50% - 2px);
            }
        }
        &.count-5 {
            > li {
                width: calc(33.333% - 2px);
            }
        }
    }
    > li .megamenu__item {
        display: block;
        max-width: 400px;
        margin: 0 auto;
        color: #fff;
        min-height: 400px;
        position: relative;
        padding: 0 35px;

        // &:before {
        //     content: '';
        //     position: absolute;
        //     bottom: 100%;
        //     left: 35px;
        //     right: 35px;
        //     height: 5px;
        //     background-color: rgba(white,.2);
        //     transition: background-color $timing $ease-std;
        // }

        @include hover() {
            &:before {
                background-color: white;
            }
        }

        .bg {
            @include ratio(65%);
            margin-bottom: 35px;
            position: relative;

            &:after {
                content: "";
                background-image: url(../img/logo-white.svg);
                background-size: contain;
                background-repeat: no-repeat;
                width: 80px;
                height: 80px;
                position: absolute;
                left: 100%;
                top: 100%;
                transform: translate(-50%, -50%);
                opacity: .5;

            }
        }

        .title {
            @include font-size(18);
            @include bold();
            padding-bottom: 15px;
            margin-bottom: 15px;
            border-bottom: 1px dotted #454546;
        }

        .text {
            @include karla();
            @include font-size(14);
            color: rgba(white,.5);

            p {
                margin-bottom: 15px;
            }
        }
        .link {
            color: white;
            @include bold();
        }
    }
}

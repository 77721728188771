.disable-scroll{
    overflow-y: hidden;
}

// ul.header-main__menu-right li.menu-item:first-child a {
//     &:after {
//         content: " ";
//         top: 100%;
//         left: 50%;
//         border: solid transparent;
//         height: 0;
//         width: 0;
//         position: absolute;
//         pointer-events: none;
//         border-color: rgba(255, 255, 255, 0);
//         border-top-color: #ffffff;
//         border-width: 5px;
//         margin-left: -5px;
//         opacity: 0;
//         transform: translateY(-5px);
//         transition: all $timing $ease-std;
//     }

//     @include hover() {
//         &:after {
//             opacity: 1;
//             transform: translateY(0);
//         }
//     }
// }

.feature-popup {
    position: absolute;
    overflow-y: scroll;

    display: block;
    opacity: 0;
    pointer-events: none;
    transition: opacity $timing*1.5 $ease-std;

    height: 100vh;
    // width: 100%;
    // top: 85px;
    z-index: 10;

    top: 144px;
    bottom: 0;
    left: 0;
    right: 0;

    padding-bottom: 144px;

    box-shadow: 0px -5px 5px rgba(0,0,0,.6);

    background-image: url('../img/whats-on-bg.jpg');
    background-size: cover;

    .mobile-close {
        // display: none;
        display: flex;
        position: fixed;
        z-index: 5;
        cursor: pointer;

        top: 155px;
        right: 50px;

        height: 40px;
        width: 40px;
        margin: 5px;
        justify-content: center;
        align-items: center;

        background-color: rgba(0, 0, 0, 0);

        svg {
            width: 25px;
            height: 25px;
        }
    }

    &.is-open {
        opacity: 1;
        pointer-events: all;

        ul li {
            transform: translateY(0);
            opacity: 1;
        }

        ul li:nth-child(1) { transition-delay: .0s; }
        ul li:nth-child(2) { transition-delay: .15s; }
        ul li:nth-child(3) { transition-delay: .3s; }
        ul li:nth-child(4) { transition-delay: .45s; }
        ul li:nth-child(5) { transition-delay: .6s; }
        ul li:nth-child(6) { transition-delay: .75s; }
        ul li:nth-child(7) { transition-delay: .9s; }
        ul li:nth-child(8) { transition-delay: 1.05s; }
        ul li:nth-child(9) { transition-delay: 1.2s; }
        ul li:nth-child(10) { transition-delay: 1.35s; }
    }

    @media (max-width: 1200px) {
        ul li {
            min-width: 33.33%;
        }
    }

    @media (max-width: 900px) {
        top: 122px;
        padding-bottom: 122px;
    }

    @media (max-width: 680px) {
        top: 62px;
        padding-bottom: 62px;
        ul li {
            min-width: 50%;
        }

        .mobile-close {
            top: 75px;
            left: 1px;
            right: unset;

            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        max-width: 1200px;
        // height: 70vh;
        margin: 0 auto;
        padding: 0;

        box-shadow: 0 0 25px 2px rbga(black,.45);
        position: relative;
        z-index: 2;
    }

    li {
        list-style: none;
        display: flex;
        flex: 1;
        min-width: 25%;

        @include reg();
        background-color: #141416;
        margin-left: 1px;
        margin-right: 1px!important;
        margin-bottom: 3px;

        opacity: 0;
        transform: translateY(-20px);

        transition: transform $timing*1.5 $ease-std,
                    opacity $timing*1.5 $ease-std;
    }

    .feature-link {
        margin: 0 auto;
        color: #fff;

        position: relative;
        padding: 0 2.3em 1.5em;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 2px;
            background-color: rgba(white,.2);
            transition: background-color $timing $ease-std;
            z-index: 1;
        }

        @include hover() {
            &:before {
                background-color: white;
            }
        }
    }

    .feature-img{
        margin-bottom: 35px;
        position: relative;

        &:after {
            // @include icons();
            content: '';
            position: absolute;
            left: 100%;
            top: 100%;
            transform: translate(-50%, -50%);
            opacity: .5;
            color: white;
            width: 4em;
            background-image: url(../img/logo-white.svg);
            background-repeat: no-repeat;
            background-size: 100% auto;
            background-position: center center;
            aspect-ratio: 100/120;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            aspect-ratio: 5/3;
            max-height: 250px;
            margin: 0;
            padding: 0;
        }
    }

    .feature-title,
    .feature-excerpt {
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;

        text-align: right;
        @include karla();
    }

    .feature-title {
        -webkit-line-clamp: 1;
        color: #FFFFFF;

        @include font-size(18);
        @include bold();
        margin-bottom: 15px;
        padding-bottom: 0;
    }

    .feature-excerpt {
        -webkit-line-clamp: 2;
        // overflow: hidden;
        // display: -webkit-box;
        // -webkit-box-orient: vertical;

        @include font-size(14);
        color: rgba(white,.5);

        margin-top: 0;
        padding-top: 15px;
        border-top: 1px dotted #454546;

        margin-bottom: 15px;
    }
}
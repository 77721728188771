// Header

.header-main {
    @include center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 50;
    transition: top 600ms cubic-bezier(0.165, 0.84, 0.44, 1);

    &__upper {
        display: none;
        padding: 20px 0 0 0;
        margin: 0 0 20px 0;
        color: #fff;
        text-align: center;

        br {
            display: none;
        }

        @include breakpoint(0,900px) {
            .container {
                position: relative;
            }
        }
    }

    .link--subscribe {
        position: absolute;
        left: 30px;
    }

    .social-links {
        position: absolute;
        right: 30px;
        top: 0;
    }

    &__upper-title {
        @include font-size(14);
        @include karla;
        @include dottedBorder($color: #fff, $orientation: horizontal, $position: bottom, $spacing: 5px, $size: 1px);
        position: relative;
        text-transform: uppercase;
        padding: 0 15px 15px 15px;
        letter-spacing: 0.08em;
        top: -3px;
    }

    // &__logo {
    //     position: absolute;
    //     z-index: 1;
    //     margin: auto;
    //     display: table;
    //     top: 50%;
    //     left: 50%;
    //     transform: translate(-50%, -25%);
    // }

    .logo-small,
    .logo-mini,
    .hamburger,
    .link--search-alt {
        display: none;
    }

    &__nav {
        @include bold;
        @include fluid(font-size, 1000px, 1500px, 16px, 18px);
        @include karla;
        position: relative;
        display: flex;
        justify-content: space-between;
        // padding: 90px 0 0 0;
        padding: 34px 0 0 0;

        ul:not(.megamenu) > li.has-dropmenu > a {
            &:after {
                content: " ";
                top: 100%;
                left: 50%;
                border: solid transparent;
                height: 0;
                width: 0;
                position: absolute;
                pointer-events: none;
                border-color: rgba(255, 255, 255, 0);
                border-top-color: #ffffff;
                border-width: 5px;
                margin-left: -5px;
                opacity: 0;
                transform: translateY(-5px);
                transition: all $timing $ease-std;
            }

            @include hover() {
                &:after {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        ul:not(.megamenu) > li > a:not(.header-main__logo),
        button {
            position: relative;
            color: #fff;
            width: auto;
            display: inline-block;
            padding: 0 0 35px 0 !important;
            
            &:before {
                @include center;
                position: absolute;
                bottom: 0;
                height: 5px;
                width: 0;
                transition: all 200ms ease-out;
                background: #fff;
                content: '';
            }

            @include hover {
                position: relative;

                &:before {
                    width: 100%;
                }
            }

            &.active:before {
                background: $blue;
                width: 100%;

                @include hover {
                    &:before {
                        background: $blue;
                        width: 100%;
                    }
                }
            }
        }

        .link--search {
            &:before {
                bottom: -1px !important;
            }
        }

        button {
            padding: 0;
            line-height: 1.2;
        }
        
        > ul:not(.megamenu) {
            display: flex;
            // width: 50%;
            align-items: center;
            gap: 1em;
            justify-content: space-between;
            max-width: 1051px;
            width: 100%;

            // @include breakpoint(901px,0) {
            //     width: calc(50% - 80px);
            // }
            
        }

    }

    &__logo {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    &__menu-left,
    &__menu-right {
        z-index: 2;
        position: relative;
    }

    &__menu-left {
        transform: translateX(-10px);
        
        > li:not(:last-child) {
            @include fluid(margin-right, 901px, 1500px, 30px, 65px);
        }
    }

    &__menu-right {
        //transform: translateX(-20px);

        > li {
            margin-left: auto;
        }

        > li:not(:first-child) {
            @include fluid(margin-left, 901px, 1500px, 30px, 65px);
        }
    }

    .page--internal & {
        position: fixed;
        display: block;
        transform: translateY(0);
        opacity: 1;

        &__wrapper {
            background: #030204;
        }

        &__upper {
            display: block;
        }

        &__logo {
            transform: translate(-50%, -50%);
        }

        .logo-large {
            display: none;
        }

        .logo-small {
            display: block;
            width: 70px;
            height: 24px;

        }

        &__nav {
            // padding: 25px 0 0 0;
            // margin: 0 0 30px 0;
            // @include font-size(14);

            ul:not(.megamenu) > li > a:not(.header-main__logo), button {
                &:before {
                    bottom: 0;
                }
            }

            @include breakpoint(901px,0) {

                .dropmenu--lists {
    
                    top: 100%;
                    
                }
    
            }

        }

        &__nav > ul:not(.megamenu) {
            // display: inline-block;
            // transform: translateX(-15px);
            //transform: none;

            > li {
                display: inline-block;
            }
        }
        
        &__menu-left {
            transform: none;
            text-align: right;
            
            > li:not(:last-child) {
                margin-right: 30px;
                margin-left: 30px;
            }

            > li:last-child {
                margin-right: 45px;
                margin-left: 30px;
            }
        }

        &__menu-right {

            > li,
            > li:not(:first-child) {
                margin-right: 30px;
                margin-left: 30px;
            }

            > li:first-child {
                margin-left: 45px !important;
                margin-left: 30px;
            }

        }
        @include breakpoint(0, 1200px) {
            &__menu-left {                
                > li:not(:last-child) {
                    margin-right: 20px;
                    margin-left: 20px;
                }
                > li:last-child {
                    margin-right: 15px;
                    margin-left: 20px;
                }
            }
    
            &__menu-right {
                > li,
                > li:not(:first-child) {
                    margin-right: 20px;
                    margin-left: 20px;
                }
                > li:first-child {
                    margin-left: 15px !important;
                    margin-left: 20px;
                }
            }
        }

        @include breakpoint(0, 1000px) {
           
            .container--main {
                padding: 0 5px;
            }
            
            &__menu-left {
                transform: none;
                text-align: right;
                
                > li:not(:last-child) {
                    margin-right: 10px;
                    margin-left: 10px;
                }
    
                > li:last-child {
                    margin-right: 15px;
                    margin-left: 10px;
                }
            }
    
            &__menu-right {
    
                > li,
                > li:not(:first-child) {
                    margin-right: 10px;
                    margin-left: 10px;
                }
    
                > li:first-child {
                    margin-left: 10px;
                }
    
            }
        }

        @include breakpoint(0, 680px) {
            &__upper {
                text-align: left;

                .container {
                    padding-left: 15px;
                }
            }

            &__upper-title {
                background-image: none;
                padding: 0 0 0 10px;
                display: inline-block;
                top: -6px;
                position: relative;

                br {
                    display: inline-block;
                }
            }

            .logo-mini {
                display: inline-block;
                height: 44px;
                width: 20px;
                margin: 5px 0 0 0;

                svg {
                    height: 44px;
                    width: 20px;
                }
            }
        }
    }

    @include breakpoint(901px, 0) {
        
        .header--hide & {
            transition: transform 400ms ease, opacity 400ms, top 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
            display: none;
        }

        .header--sticky & {
            display: block;
            transform: translateY(-60px);
            opacity: 0;
            position: fixed;

            &__wrapper {
                background: #030204;
            }

            .container--main {
                max-width: 950px;
            }

            &__upper {
                display: block;
            }

            &__logo {
                width: 70px;
                height: 24px;
                margin-right: 30px;
                // transform: translateY(-50%);
                margin-top: -3px;
            }

            .logo-large, .logo {
                display: none;
            }

            .logo-small {
                display: block;
            }

            &__nav {
                padding: 25px 0 0 0;
                // margin: 0 0 30px 0;
                @include font-size(14);

                ul:not(.megamenu) > li > a:not(.header-main__logo), button {
                    &:before {
                        bottom: 0;
                    }
                }
            }

            &__menu-left {
                // transform: translateX(-12%);
                
                > li:not(:last-child) {

                    @include fluid(margin-right, 901px, 1500px, 30px, 35px);

                }
            }
        
            &__menu-right {
        
                > li:not(:first-child) {

                    @include fluid(margin-left, 901px, 1500px, 30px, 35px);
                    
                }
            }

        } 
        
        //if sticky or is an internal page
        .header--show &,
        .page--internal & {
            position: fixed;
            transform: translateY(-60px);
            opacity: 1;
            transition: top 600ms cubic-bezier(0.165, 0.84, 0.44, 1), opacity 400ms, transform 400ms;
        }

        .tablet & {
            transform: translateY(0) !important;
        }

        .header--show-alt.header--show &,
        .header--show-alt.page--internal & {
             transform: translateY(0);
        }

        .social-links {
            top: 21px;
        }
    }

    @include breakpoint(0, 900px) {
        position: fixed;
        display: block;
        transform: translateY(0);
        opacity: 1;

        &__wrapper {
            background: #030204;
        }

        &__upper {
            display: block;
        }

        &__logo {
            width: 70px;
            height: 24px;
            top: 25px;
        }

        .logo-large {
            display: none;
        }

        .header-main__logo{
            display: none;
        }

        .logo-small {
            display: block;
        }

        &__nav {
            padding: 25px 0 0 0;
            //margin: 0 0 30px 0;
            @include font-size(14);

            ul:not(.megamenu) > li > a:not(.header-main__logo), button {
                &:before {
                    bottom: 0;
                }
            }
        }

        &__nav > ul:not(.megamenu) {
            display: inline-block;
            transform: translateX(-15px);

            > li {
                display: inline-block;
            }
        }
        
        &__menu-left {
            transform: none;
            text-align: right;
            
            > li:last-child,
            > li:not(:last-child) {
                margin-right: 10px;
                margin-left: 10px;
            }
        }

        &__menu-right {
    
            > li,
            > li:not(:first-child) {
                margin-right: 10px;
                margin-left: 10px;
            }
        }
    }

    @include breakpoint(0, 680px) {
        .link--subscribe,
        .social-links,
        &__nav {
            display: none;
        }

        &__upper {
            height: 60px;
            padding: 0;
            margin: 0;
        }

        .hamburger,
        .link--search-alt {
            display: block;
        }

        .link--search-alt {
            position: absolute;
            right: 60px;
            top: 0;
        }
    }

    @include breakpoint(0, 400px) {
        .link--search-alt {
            width: 40px;
        }

        &__upper-title {
            @include font-size(13);
        }
    }
}

.page--internal.search-open .header-main,
.page--front.header--hide.header--sticky.header--show.search-open .header-main {
    top: 125px;
    transition: top 600ms cubic-bezier(0.165, 0.84, 0.44, 1), transform 400ms;
}

.page--front.search-open .header-main {
    transition: top 600ms cubic-bezier(0.165, 0.84, 0.44, 1);

    @include breakpoint(0, 900px) {
        top: 125px;
    }
}

.page--front {

    .header-main {
        &__nav ul:not(.megamenu) > li > a.active:before {
            content: none;
        }
    }

    @include breakpoint(0, 900px) {
        .header-main__upper-title {
            display: none;
        }

        .logo-small {
            display: none;
        }

        .header-main__logo {
            width: 130px;
            height: 130px;
            top: 110px;
        }

        // .logo-large {
        //     display: block;
        // }
    }

    @include breakpoint(0, 680px) {
        .header-main__upper {
            text-align: left;

            .container {
                padding-left: 15px;
            }
        }

        .header-main__upper-title {
            background-image: none;
            padding: 0 0 0 10px;
            display: inline-block;
            top: -6px;
            position: relative;

            br {
                display: inline-block;
            }
        }

        .logo-mini {
            display: inline-block;
            height: 44px;
            width: 20px;
            margin: 5px 0 0 0;

            svg {
                height: 44px;
                width: 20px;
            }
        }
    }
}
// Page Contact
// --------------------------------------------------
.page--contact {
    main.content {
        position: relative;
        padding-top: 0 !important;
    }

    .panel--logo-gallery {
        border-top: 2px solid #000;
        border-bottom: 0;
        margin-bottom: 0 !important;
    }

    .content__upper {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .content__lower {
        display: block;
    }

    @include breakpoint(0, 850px) {
        .content__upper {
            display: block;
        }
    }
}

.panel--form {
    width: 70%;
    flex-basis: auto;

    .container {
        max-width: 1100px;
    }

    .panel__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 0 40px 0;
    }

    .panel__title {
        @include fluid(font-size, 700px, 1500px, 22px, 30px);
        display: inline-block;;
    }

    @include breakpoint(0, 1600px) {
        width: 60%;
    }

    @include breakpoint(0, 1340px) {
        width: calc(100% - 500px);
    }

    @include breakpoint(0, 950px) {
        width: 100%;
        max-width: 700px;
        margin: 0 auto;
    }

    @include breakpoint(0, 450px) {
        .panel__header {
            display: block;
        }

        .panel__title {
            display: block;
            margin: 0 0 20px 0;
        }
    }
  
}

.panel--social {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 160px);
    padding: 50px 0 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .panel__title {
        @include fluid(font-size, 700px, 1500px, 22px, 30px);
        display: inline-block;
    }

    .social-links {
        margin-left: auto;

        a {
            width: 30px;
            height: 30px;
            margin: 0 15px 0 0;

            &:before {
                font-size: 30px;
                color: #000;
            }

            @include hover {
                &:before {
                    color: rgba(#000, 0.5);
                }
            }
        }
    }

    @include breakpoint(0, 850px) {
        padding-bottom: 60px;
    }

    @include breakpoint(0, 450px) {
        display: block;

        .panel__title {
            margin: 0 0 30px 0;
        }
    }
}

.panel--cta50 {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 500px;
    color: #fff;
    padding: 0 30px;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (11 / 5) * 100%;
    }

    .panel__content {
        @include fluid(top, 851px, 1500px, 60px, 160px);
        @include center;
        position: absolute;
        width: 100%;
        max-width: 400px;
    }

    .panel__title {
        @include fluid(font-size, 700px, 1500px, 22px, 30px);
        margin: 0 0 30px 0;
    }

    .panel__copy {
        @include fluid(font-size, 700px, 1500px, 18px, 22px);
        @include serif;
        margin: 0 0 30px 0;
    }

    .btn {
        @include fluid(font-size, 700px, 1500px, 16px, 18px);
    }

    @include breakpoint(0, 1600px) {

        .panel__content {
            max-width: calc(100% - 60px);
        }
    }

    @include breakpoint(0, 950px) {
        position: relative;
        width: 100%;
        max-width: 700px;
        margin: 0 auto 40px;
        height: auto;


        &:before {
            content: none;
        }

        .panel__content {
            top: auto !important;
            padding: 60px 0;
            position: relative;
            max-width: 700px;
        }

    }
}

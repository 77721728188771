//  Footer
// --------------------------------------------------

.footer-main {
    @include fluid(padding-top, 700px, 1500px, 60px, 145px);
    @include fluid(padding-bottom, 700px, 1500px, 60px, 145px);
    background-color: #141416;
    background-image: url(../img/ui/footer-bg.jpg);
    background-position: bottom right;
    color: #fff;

    &__upper {
        @include fluid(padding-bottom, 700px, 1500px, 80px, 100px);
        @include clearfix;
        position: relative;

        &:before {
            @include dottedBorder($color: #404042, $orientation: horizontal, $position: bottom, $spacing: 8px, $size: 2px);
            position: absolute;
            bottom: 0;
            left: 0;
            height: 1px;
            width: calc(100% - 260px);
            content: '';
        }
    }

    .social-links {
        width: 120px;
        margin: 0 4.5% 10.5% 4.5%;
        float: left;

        &__title {
            @include fluid(font-size, 800px, 1500px, 18px, 20px);
            margin: 0 0 50px 0;        
        }

        li {

            width: 25px;
            height: 25px;

        }

        a {
            color: #fff;

            @include hover {
                &:before {
                    color: rgba(#fff, 0.5);
                }
            }
        }
    }
    
    .logo-large {
        position: absolute;
        width: 200px;
        height: 200px;
        right: 0;
        bottom: -100px;
    }

    .subscribe-form {
        float: left;
		width: 100%;
		max-width: 500px;
	}

    .subscription-error,
    .subscription-success {
        @include karla;
        margin: 10px 0 0 0;
    }

    input:-webkit-autofill {
        -webkit-box-shadow: 0 0 0 1000px #141416 inset;
        -webkit-text-fill-color: #fff !important;
    }

    &__lower {
        @include fluid(padding-top, 700px, 1500px, 80px, 100px);
        @include karla;
        display: flex;
        justify-content: space-between;

        p {
            @include font-size(14);
            margin: 0;
            color: rgba(#fff, 0.4);
            text-align: right;

            a {
                color: rgba(#fff, 0.4);
                transition: border 200ms, color 200ms;;
                border-bottom: 1px solid rgba(#fff, 0.4);
        
                @include hover {
                    border-color: transparent;;
                    color: rgba(#fff, 1);
                }
            }
        }
    }

    &__lower-nav {
        @include clearfix;

        a {
            color: #fff;

            @include hover {
                color: rgba(#fff, 0.5);
            }
        }

        ul {
            float: left;

            &:not(:first-of-type) {
                margin-right: 70px; 
            }
        }

        li {
            margin: 0 0 10px 0;
        }
    }

    &__lower-credits {
        align-self: flex-end;
    }

    @include breakpoint(0, 1050px) {
        &__upper {
    
            &:before {
                width: calc(100% - 160px);
            }
        }

        .social-links {
            width: 120px;
            margin-left: 0px;
        }

        .subscribe-form {
            float: left;
            width: 100%;
            max-width: calc(100% - 200px);
        }
        
        .logo-large {
            position: absolute;
            width: 130px;
            height: 130px;
            right: 0;
            bottom: -65px;
        }
    }

    @include breakpoint(0, 900px) {
        &__lower {
            display: block;

            p {
                text-align: left;
            }
        }

        &__lower-nav {
            ul {
    
                &:not(:first-of-type) {
                    margin-left: 50px; 
                }
            }
        }

        &__lower-credits {
            display: block;
            width: 100%;
            margin: 40px 0 0 0;
        }
    }

    @include breakpoint(0, 600px) {
        .social-links {
            float: none;
            width: 100%;
            margin-left: 0;

            &__title {
                margin: 0 0 20px 0;
            }
        }

        .subscribe-form {
            float: none;
            width: 100%;
            max-width: none;
            margin: 0 0 50px 0;
        }

        &__lower-nav {

            ul {
                display: block;
                float: none;
                margin-left: 0;
            }

            ul:not(:first-of-type) {
                margin-left: 0;
            }
        }
    }

}


.welcome-to-country{

    @include breakpoint(0, 1050px) {
        display: inline-block;
        margin: 2.5% 0;
        position: relative;
    }
}

#disclaimer {

    margin-bottom: calc(80px + 20 * (100vw - 700px) / 800);
    
    h2 {

        margin-bottom: 1em;

    }

    h4 {

        font-weight: 400;
        line-height: 1.25;

    }

}
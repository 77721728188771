// Cards
// --------------------------------------------------
.card {

}

.card--article {
	position: relative;

	a {
		color: inherit;
	}

	.card__content {
		@include bold;
		@include karla;
		z-index: 5;
	}

	.card__title {
		@include bold;
		@include fluid(font-size, 900px, 1500px, 18px, 26px);
		max-width: 420px;
	}

	.card__cat {
		@include fluid(font-size, 900px, 1500px, 13px, 16px);
		display: block;
		margin: 0 0 10px 0;
	}

	.card__img {
		@include overlay-custom(#000, 0);
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 1;
	}

	a {
		@include hover {
			.card__img {
				@include overlay-custom(#000, 0.2);
			}
		}
	}
}

.card--article,
.card--news {

    .card__pill {
        @include bold;
        @include karla;
        @include font-size(12);
        position: absolute;
        top: 0;
        right: 0;
        z-index: 5;
        color: #fff;
        padding: 6px 17px;
        background: #0f0e0e;
        z-index: 5;
    }
}

.card--article-dark {
	background-color: $black;

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: (21 / 29) * 100%;
	}

	a {
		color: #fff;
	}

	.card__content {
		position: absolute;
		left: 0;
		bottom: 0;
		@include fluid(padding-top, 700px, 1500px, 20px, 45px);
		@include fluid(padding-right, 700px, 1500px, 20px, 45px);
		@include fluid(padding-bottom, 700px, 1500px, 20px, 45px);
		@include fluid(padding-left, 700px, 1500px, 20px, 45px);
	}

	.card__img {
		height: calc(100% - 60px);
		background-color: $black;

		&:after {
			position: absolute;
			left: 0;
			bottom: 0;
			height: 40%;
			width: 100%;
			background: linear-gradient(to bottom, rgba(26,26,29,0) 0%,rgba(26,26,29,1) 100%);
			z-index: 5;
			content: '';
		}
	}
}

.card--article-light {

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: (21 / 29) * 100%;
	}

	a {
		color: #fff;

		@include hover {
			.card__img {
				@include overlay-custom(#fff, 0.2);
			}
		}
	}

	.card__pill {
		left: 0;
		right: auto;
	}

	.card__content {
		position: absolute;
		right: 0;
		bottom: 0;
		width: calc(50% + 10px);
		background: #fff;
		color: $black;
		@include fluid(padding-top, 700px, 1500px, 20px, 45px);
		@include fluid(padding-right, 700px, 1500px, 20px, 45px);
		@include fluid(padding-left, 700px, 1500px, 20px, 45px);
	}

	.card__img {
		height: 100%;
	}
}

.card--article-stacked {
	position: absolute;
	top: 0;
	right: 0;
	width: calc(100% - 70px);
	height: 100%;

	.card__img {
		height: calc(100% - 170px);
	}

	.card__pill {
		right: auto;
		left: 0;
	}

	.card__content {
		position: absolute;
		left: 0;
		bottom: 0;
		//min-height: 170px;
		background-color: #fff;
		@include fluid(padding-top, 700px, 1500px, 20px, 40px);
		@include fluid(padding-right, 700px, 1500px, 20px, 45px);
		@include fluid(padding-bottom, 700px, 1500px, 20px, 40px);
		color: $black;
		z-index: 10;
	}
}
.card--article-halfs {
	height: 100%;

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: (21 / 29) * 100%;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		display: flex;
		width: 100%;
		height: 100%;
		align-items: center;
		overflow: hidden;
	}

	.card__content {
		position: relative;
		color: $black;
		z-index: 10;
		@include fluid(padding-bottom, 650px, 1500px, 15px, 45px);

		&:after {
			@include dottedBorder($color: $black, $orientation: horizontal, $position: bottom, $spacing: 8px, $size: 2px);
			position: absolute;
			left: 0;
			bottom: 0;
			width: 500%;
			height: 1px;
			content: '';
		}
	}

	.card__title {
		width: calc(50% - 20px);
	}

	.card__img {
		position: absolute;
		top: 0;
		right: 0;
		left: auto;
		width: calc(50% - 10px);
	}
}

.card--article-list {
	//height: 100%;

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: (21 / 29) * 100%;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden;
		background: $black;
		padding-left: 50%;
	}

	.card__title {
		@include fluid(font-size, 650px, 1500px, 16px, 20px);
		margin: 0 0 10px 0;
	}

	.card__content {
		position: relative;
		z-index: 10;
		height: 100%;
		display: flex;
		align-items: flex-end;
		@include fluid(padding-bottom, 650px, 1500px, 20px, 45px);
		@include fluid(padding-left, 650px, 1500px, 20px, 45px);
		@include fluid(padding-right, 650px, 1500px, 20px, 45px);
		color: #fff;

		&:after {
			@include icons;
			@include fluid(top, 650px, 1500px, 10px, 45px);
			@include fluid(left, 650px, 1500px, 20px, 45px);
			@include fluid(font-size, 650px, 1500px, 60px, 100px);
			position: absolute;
			color: rgba(#fff, 0.22);
			content: "\E014";
		}
	}

	p {
		@include serif;
		margin-bottom: 25px;
	}

	.card__img {
		position: absolute;
		top: 0;
		left: 0;
		right: auto;
		width: 50%;
	}

	@include hover {
		.btn {
			border-color: transparent;
			background: #fff;
			color: $black;
		}
	}

	@include breakpoint(676px, 1100px) {
		// .btn,

		.card__title {
			margin: 0 0 20px 0;
		}

		p {
			display: none;
		}

		.btn {
			padding: 9px 15px;
			width: 116px;
		}
	}

	@include breakpoint(0, 450px) {
		&:before {
			content: none;
		}

		a {
			padding: 0 !important;
			position: relative;
		}

		.card__content {
			background: $black;
			padding-top: 90px;
		}

		.card__img {
			position: relative;
			width: 100%;
			padding-top: (29 / 21) * 100%;
		}
	}

}

//variation on the above ^

.home-grid__row--landscape-stacked-rev {
	.card--article-halfs {

		.card__content {
			padding-left: 50%;
		}

		.card__cat,
		.card__title {
			@include fluid(padding-left, 700px, 1500px, 0px, 25px);
		}

		.card__title {
			width: 100%;
		}

		.card__img {
			position: absolute;
			top: 0;
			right: auto;
			left: 0;
			width: calc(50% - 10px);
		}
	}
}

.card--is-video {
	.card__cat {
		position: relative;
		display: inline-block;
		border: 2px solid #fff;
		border-radius: 40px;
		background: $black;
		padding: 5px 15px 5px 35px;
		margin: 0 0 15px 0;
		transition: color 200ms, background 200ms;

		&:before {
			@include icons;
			position: absolute;
			font-size: 19px;
			content: "\E002";
			left: 8px;
			top: calc(50% - 10px);
		}
	}

	a {
		@include hover {
			.card__cat {
				border-color: transparent;
				background: #fff;
				color: $black;
			}
		}
	}
}

.card--pick {
	@include clearfix;
	position: relative;
	display: block;
	color: $black;
	margin: 0 0 50px 0;

	a {
		color: $black;

		@include hover {
			.card__title {
				color: $blue;
			}
		}
	}

	.card__img-wrapper {
		width: 30%;
		float: left;
	}

	.card__img {
		position: relative;
		padding-bottom: (2 / 3) * 100%;
		@include overlay-custom(#000, 0);
	}

	.card__title {
		@include fluid(font-size, 700px, 1500px, 18px, 25px);
		@include bold;
		margin: 0 0 20px 0;
		transition: color 200ms;

		em {
			color: $blue;

			span {
				color: #dedede;
			}
		}
	}

	.card__content {
		@include fluid(padding-left, 700px, 1500px, 40px, 100px);
		position: relative;
		float: left;
		width: 70%;
	}

	.card__copy {
		@include fluid(font-size, 700px, 1500px, 16px, 20px);
		width: calc(100% - 180px);
	}

	p {
		max-width: 375px;
	}

	.btn {
		position: absolute;
		right: 0;
		top: calc(50% - 20px);
	}

	@include hover {
		.card__img {
			@include overlay-custom(#000, 0.2);
		}
	}

	@include breakpoint(0, 750px) {
		.card__copy {
			width: 100%;
		}

		.btn {
			position: relative;
			right: auto;
			top: auto;
			margin: 20px 0 0 0;
		}
	}

	@include breakpoint(0, 550px) {
		.card__img-wrapper,
		.card__content {
			width: 100%;
			float: none;
		}

		.card__img-wrapper {
			margin: 0 0 30px 0;
		}

		.card__content {
			padding: 0;
		}

	}
}

.card--award {
	position: relative;
	background: $black;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: (91 / 76) * 100%;
	}

	a {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	.card__img {
		position: relative;
		height: calc(100% - 225px);
		width: 100%;
	}

	.card__rank {
		@include karla;
		@include bold;
		@include fluid(font-size, 700px, 1500px, 16px, 20px);
		position: absolute;
		right: 0;
		bottom: 0;
		width: 50px;
		height: 50px;
		color: #fff;
		background: $blue;
		text-align: center;
		padding: 11px;
		display: none;
	}

	.card__content {
		position: absolute;
		background: $black;
		color: #fff;
		height: 225px;
		padding: 20px;
		text-align: center;
		bottom: 0;
		transition: height 400ms ease-out, background 400ms;
		width: 100%;
	}

	.card__content-inner {
		position: absolute;
		top: 40%;
		transform: translateY(-50%);
		width: 100%;
		left: 0;
		transition: all 200ms;
	}

	.card__title {
		@include center;
		@include fluid(font-size, 700px, 1500px, 18px, 25px);
		@include bold;
		position: relative;
		width: 100%;
		transition: font-size 200ms;

		span {
			@include fluid(font-size, 700px, 1500px, 14px, 16px);
			display: block;
			margin: 5px 0 0 0;
			transition: font-size 200ms;
		}

		&:after {
			// @include icons;
			@include center;
			position: absolute;
			// font-size: 110px;
			// color: rgba(#fff, 0.22);
			content: "";
			background-image: url(../img/logo-white.svg);
			background-repeat: no-repeat;
			background-size: 100% auto;
			background-position: center center;
			width: 110px;
			aspect-ratio: 100/120;
			opacity: .22;
			top: calc(50% - 54px);
			transition: font-size 200ms, top 200ms;
		}

	}

	.card__logo-sm {
		position: absolute;
		left: 20px;
		bottom: 20px;
		opacity: 0.3;
		transition: opacity 200ms;
		width: 50px;
	}

	.btn {
		@include center;
		position: absolute;
		top: 90px;
		width: 160px;
		opacity: 0;
		transform: translateY(20px);
	}

	.card__sponsor {
		@include karla;
		@include font-size(14);
		@include bold;
		position: absolute;
		width: 100%;
		text-align: center;
		left: 0;
		bottom: 30px;
		z-index: 5;
		color: rgba(#fff, 0.2);
		opacity: 1;
		pointer-events: none;

        img {
            opacity: 0;
            margin-bottom: 10px;
            transform: translateY(20px);
            backface-visibility: hidden;
        }

        &-label {
            display: block;
        }
	}

	@include breakpoint(0, 900px) {

		.card__content {
			height: 120px;
		}

		.card__img {
			height: calc(100% - 120px);
		}

		.card__title {

			&:after {
				font-size: 80px;
				top: calc(50% - 40px);
			}
		}

		@include hover {
			.card__title {
				&:after {
					font-size: 120px;
					top: calc(50% - 60px);
				}
			}
		}
	}

	// if featured

	&-featured {
		&:before {
            padding-top: (57 / 58) * 100%;
		}

		.card__img {
			height: calc(100% - 205px);
		}

		.card__content {
			height: 205px;
		}

		@include breakpoint(0, 900px) {
			&:before {
				padding-top: (21 / 19) * 100%;
			}

			.card__sponsor {
				bottom: 30px;
			}
		}
	}

	&:not(.card--award-featured) {
		@include breakpoint(0, 1100px) {
			.card__sponsor {
				bottom: 30px;
			}
		}
	}

	// ranked

	&-ranked {
		.card__rank {
			display: block;
		}
	}

	@include hover {
		.card__content {
			position: absolute;
			height: 100%;
			width: 100%;
			z-index: 5;
			background: rgba($black, 0.96);
		}

		.card__content-inner {
			top: 35%;
		}

		.card__title {
			@include fluid(font-size, 700px, 1500px, 22px, 28px);

			span {
				@include fluid(font-size, 700px, 1500px, 21px, 23px);
			}

			&:after {
				font-size: 160px;
				top: calc(50% - 80px);
			}
		}

		.btn {
			opacity: 1;
			transform: translateY(0);
			transition: opacity 800ms 300ms, background 200ms, transform 800ms 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
		}

		.sponsor--card-award {
			opacity: 0;
		}

		.card__sponsor {
			pointer-events: all;

            img {
                opacity: 1;
                transform: translateY(0);
                transition: opacity 800ms 300ms, transform 800ms 300ms cubic-bezier(0.075, 0.82, 0.165, 1);
            }
		}
	}
}

.card--gallery {
	padding-top: (25 / 38) * 100%;
}

.card--gallery-video {
	@include fluid(padding, 700px, 1500px, 20px, 100px);
	position: relative;
	color: #fff;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: (37 / 59) * 100%;
	}

	a {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		color: #fff;
	}

	.card__content {
		@include fluid(bottom, 700px, 1500px, 20px, 100px);
		@include fluid(left, 700px, 1500px, 20px, 100px);
		position: absolute;
		z-index: 5;
		width: 100%;
		max-width: 475px;
	}

	.card__title {
		@include fluid(font-size, 700px, 1500px, 20px, 30px);
	}

	.card__copy {
		@include fluid(font-size, 700px, 1500px, 14px, 20px);
	}

	.btn {
		margin: 0 0 20px 0;
	}

	.card__img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	@include hover {
		.btn--watch {
			border-color: transparent;
			background: #fff;
			color: $black;
		}
	}
}

.card--team {
	.card__img {
		padding-top: (25 / 18) * 100%;
		margin: 0 0 15px 0;
	}

	.card__title {
		@include fluid(font-size, 700px, 1500px, 14px, 16px);

		span {
			@include serif;
			display: block;
			margin: 8px 0 0 0;
			font-weight: normal;
		}
	}
}

.card--team-empty {
	background: rgba(#fff, 0.02);
	box-shadow: 0 0 30px 0 rgba(#000, 0.15);
	transition: background 200ms;

	a {
		position: relative;
		display: block;
		width: 100%;
		height: 100%;
		color: #fff;

		&:before {
			@include icons;
			@include center;
			position: absolute;
			font-size: 110px;
			color: rgba(#fff, 1);
			content: "\E014";
			top: 30px;
			width: 100%;
			text-align: center;
		}
	}

	.card__content {
		padding-top: (25 / 18) * 100%;
	}

	.card__title {
		@include center;
		position: absolute;
		bottom: 30px;
		padding: 0 25px;
		text-align: center;
	}

	@include hover {
		background: rgba(#fff, 0.05);
	}
}

.card--finalist {
	overflow: hidden;
	-webkit-backface-visibility: hidden;

	a {
		color: #fff;

		@include hover {
            .card__read-review {
                opacity: 1;
            }
			.card__img {
				@include overlay-custom(#000, 0.2);
			}
            .card__title:after {
                color: rgba(#fff, 1);
            }
		}
	}

	.card__img {
		@include overlay-custom(#000, 0);
		position: relative;
		padding-top: (13 / 19) * 100%;
		margin: 0 0 15px 0;

        &.black-white {
            filter: grayscale(100%);
            transform: translateZ(0);
        }
	}

    .card__read-review {
        @include karla;
        @include bold;
        position: absolute;
        color: #fff;
        text-align: center;
        width: 100%;
        top: 50%;
        opacity: 0;
        transition: opacity 200ms;
    }

	.card__rank {
		@include karla;
		@include bold;
		@include fluid(font-size, 700px, 1500px, 16px, 20px);
		position: absolute;
		right: 0;
		bottom: 0;
		width: 50px;
		height: 50px;
		color: #fff;
		background: $blue;
		text-align: center;
		padding: 11px;
	}

	.card__title {
		@include fluid(font-size, 700px, 1500px, 16px, 20px);
        position: relative;

		span {
			@include fluid(font-size, 700px, 1500px, 14px, 16px);
			display: block;
			margin: 5px 0 0 0;
			color: rgba(#fff, 0.3);
		}

        &:after {
            @include icons;
            content: "\E013";
            color: rgba(#fff, 0.3);
            font-size: 14px;
            margin: 0 0 0 15px;
            transition: color 200ms, transform 200ms;
            position: absolute;
            top: 0;
            right: 16px;
        }
	}
}

.card--img {
	position: relative;

	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: (39 / 58) * 100%;
	}

	.card__img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.card--news {
	a {
		color: $black;

		@include hover {
			.card__img {
				@include overlay-custom(#000, 0.2);
			}

			.btn {
				border-color: transparent;
				background: #fff;
				color: $black;
			}
		}
	}

	.card__img {
		@include overlay-custom(#000, 0);
		position: relative;
		margin: 0 0 15px 0;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 65.78947%;

		// &:after {
		// 	padding-top: (25 / 38) * 100%;
		// 	position: relative;
		// 	display: block;
		// 	content: '';
		// }
	}

	.card__title {
		max-width: 330px;
		line-height: 1.3;
	}

	.btn {
		@include center;
		position: absolute;
		z-index: 5;
		width: 106px;
		top: calc(50% - 18px);
	}

	.card__title {
		@include fluid(font-size, 700px, 1500px, 18px, 20px);

		span {
			@include fluid(font-size, 700px, 1500px, 14px, 16px);
			display: block;
			margin: 0 0 5px 0;
		}
	}
}

.card--feature {
	a {
		color: #9c9c9c;

		@include hover {
			.card__img {
				@include overlay-custom(#000, 0.2);
			}

			.btn {
				border-color: transparent;
				background: #fff;
				color: $black;
			}
		}
	}

	.card__content {
		display: flex;
		justify-content: space-between;
	}

	.card__img {
		@include overlay-custom(#000, 0);
		position: relative;
		//display: flex;
		//align-items: center;
		//justify-content: center;
		width: calc(100% - 235px);

		&:after {
			padding-top: (36 / 55) * 100%;
			position: relative;
			display: block;
			content: '';
		}
	}

	.btn {
		@include center;
		position: absolute;
		z-index: 5;
		width: 106px;
		top: calc(50% - 18px);
	}

	.card__caption {
		@include fluid(font-size, 700px, 1500px, 14px, 16px);
		padding: 0 0 0 50px;
		width: 235px;
		color: #9c9c9c;
	}

	@include breakpoint(0, 950px) {
		.card__content {
			display: block;
		}

		.card__img,
		.card__caption {
			width: 100%;
		}

		.card__caption {
			padding: 0;
			margin: 15px 0 0 0;
		}
	}

	&-gallery {

		columns: 3;
		column-gap: 1em;

		> figure {

			margin: 0 auto 1em;
			line-height: 0;
			cursor: pointer;

		}

		@include breakpoint(0, 950px) {

			columns: 2;

		}

	}

	&-carousel.slick-slider {

		@each $s in left, right {
			margin-#{$s}: -1em;
		}

		figure {

			margin: 0;
			line-height: 0;
			cursor: pointer;
			
			@each $s in left, right {
				padding-#{$s}: 1em;
			}

			> img {

				width: auto;
				height: 75vh;
				object-fit: cover;
				max-width: var(--slide-mw);

			}

		}

		.slick-dots {

			list-style: none;
			display: flex;
			margin: 0;
			padding: 1em .25em;

			> li {

				margin: 0;
				padding: 0 .5em;

				> button {

					width: 0.5em;
					height: 0.5em;
					padding:0;
					border-radius: 100%;
					text-indent: 999px;
					overflow: hidden;
					transition: $timing $ease-std background-color;

				}

				&.slick-active > button {

					background-color: $color_2;

				}
				
			}

		}

	}

}

p + .card--feature {
	margin: 65px 0;
}

.card--feature-lg {
	a {

		@include hover {
			.card__img {
				@include overlay-custom(#000, 0.2);
			}

			.btn {
				border-color: transparent;
				background: #fff;
				color: $black;
			}
		}
	}

	.card__pill {
		@include bold;
		@include karla;
		@include font-size(12);
		position: absolute;
		top: 0;
		right: 0;
		z-index: 5;
		color: #fff;
		padding: 6px 17px;
		background: #0f0e0e;
		z-index: 5;
	}

	.card__content {
		display: flex;
		justify-content: space-between;
	}

	.card__img {
		@include overlay-custom(#000, 0);
		position: relative;
		width: 60%;

		&:after {
			padding-top: (23 / 35) * 100%;
			position: relative;
			display: block;
			content: '';
		}
	}

	.card__content-inner {
		@include fluid(padding-left, 700px, 1500px, 40px, 85px);
		width: 40%;
	}

	.card__title {
		@include fluid(font-size, 700px, 1500px, 18px, 25px);
		margin: 0 0 20px 0;

		span {
			@include fluid(font-size, 700px, 1500px, 14px, 16px);
			display: block;
			margin: 0 0 10px 0;
		}
	}

	.card__caption {
		@include fluid(font-size, 700px, 1500px, 16px, 20px);
		margin: 0 0 30px 0;
	}

	&.card--feature-lg-reverse {
		.card__content {
			flex-flow: row;
			flex-direction: row-reverse;
		}

		.card__content-inner {
			@include fluid(padding-right, 700px, 1500px, 40px, 85px);
			padding-left: 0 !important;
		}
	}

	@include breakpoint(0, 650px) {
		.card__content {
			display: block;
		}

		.card__img,
		.card__content-inner {
			width: 100%;
		}

		.card__content-inner {
			padding: 0;
			margin: 15px 0 0 0;
		}
	}
}

.card--top50 {
	@include fluid(padding-top, 700px, 1500px, 30px, 75px);
	@include fluid(padding-bottom, 700px, 1500px, 30px, 75px);
	@include fluid(font-size, 700px, 1500px, 16px, 20px);
	position: relative;
	width: 100%;
	padding-left: 50%;
	padding-right: 8.5%;
	color: #fff;
	margin-bottom: 60px;

	a {
		display: block;
		color: #fff;
	}

	.card__content {
		position: relative;
		z-index: 5;

	}

	.card__img {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
	}

	.card__title {
		@include fluid(font-size, 700px, 1500px, 24px, 30px);
		margin: 0 0 20px 0;
	}

	.btn {
		margin: 30px 0 0 0;
	}

	@include breakpoint(0, 600px) {
		padding-left: 8.5%;
		text-align: center;
	}

	@include hover {
		.btn {
			border-color: transparent;
			background: #fff;
			color: $black;
		}
	}
}

.card--50 {
	@include fluid(margin-bottom, 700px, 1500px, 60px, 150px);

	a {
		@include hover {
			.card__title {
				color: $blue;
			}

			.card__img {
				@include overlay-custom(#000, 0.2);
			}
		}
	}

	.card__inner {
		transition: opacity $waypoint-duration linear,
		transform $waypoint-duration ease;
		opacity: 0;
		will-change: opacity, transform;
	}

	&:not(.card--50-rev) {
		.card__inner {
			transform: translateX(-20px);
		}
	}

	.card__upper {
		position: relative;
		display: block;
		width: 100%;
		margin: 0 0 60px 0;

		.card__title {
			@include fluid(font-size, 700px, 1500px, 32px, 40px);
			@include bold;
			margin: 0 0 40px 0;
			transition: color 200ms;

			em {
				color: $blue;

				span {
					color: #dedede;
				}
			}
		}

		.card__upper-content {
			position: relative;
			width: 100%;
		}

		.card__copy {
			@include fluid(font-size, 700px, 1500px, 18px, 22px);
			position: relative;
			padding-right: 200px;
		}

		p {
			max-width: 800px;
		}

		.btn {
			position: absolute;
			right: 0;
			top: calc(50% - 20px);
		}
	}

	.card__lower {
		position: relative;
		display: block;
		width: 100%;

		.card__lower-content {
			display: flex;
			justify-content: space-between;

			& > a {
				width: calc(100% - 315px);
				flex-basis: auto;
				border: 0 !important;
				display: inline-block;
			}
		}

		.card__img {
			@include overlay-custom(#000, 0);
			position: relative;
			width: 100%;

			&:after {
				padding-top: (2 / 3) * 100%;
				position: relative;
				display: block;
				content: '';
			}
		}

		.card__content-inner {
			width: 255px;
			flex-basis: auto;
			align-self: flex-end;
		}

	}

	.card__meta-item {
        @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
        padding: 0 0 25px 0;
        margin: 0 0 22px 0;
    }

    a:not(.btn):not(.link) {
        color: inherit;
        transition: border 200ms;
        border-bottom: 1px solid $black;

        @include hover {
            border-color: transparent;
        }
    }

    dl {
        display: block;
        margin: 0 0 45px 0;
    }

    dt,
    dd {
        display: inline-block;
    }

    dt {
        @include bold;
        @include karla;
        @include font-size(16);
        //margin: 0 60px 0 0;
        width: 125px;
    }

    dd {
        @include font-size(18);
        margin: 0;
    }

    .card__meta {
        @include karla;
        @include font-size(14);
        line-height: 1.1;
        margin: 40px 0 0 0;
        display: block;

        strong {
            @include bold;
        }

        p + p {
            margin: 10px 0 0 0;
        }
	}

	&.waypoint-reveal {
		.card__inner {
			transition: opacity $waypoint-duration linear,
					transform $waypoint-duration ease;
			opacity: 1;
			transform: translate(0);
		}
	}

	&.card--50-rev {

		.card__inner {
			transform: translateX(20px);
		}

		.card__lower {

			.card__lower-content {
				flex-direction: row-reverse;
			}
		}
	}

	&.card--50-rev.waypoint-reveal {
		.card__inner {
			opacity: 1;
			transform: translate(0) !important;
		}
	}

	@media screen and (max-width:800px) {
		.card__inner {
			opacity: 1 !important;
			transform: translateX(0) !important;
		}
    }

	@include breakpoint(0, 650px) {
		.card__upper {
			margin: 0 0 30px 0;

			.card__copy {
				padding-right: 0;
			}

			.btn {
				position: relative;
				right: auto;
				top: auto;
				margin: 30px 0 0 0;
			}
		}

		.card__lower {
			.card__lower-content {
				display: block;

				& > a {
					width: 100%;
				}
			}

			.card__img {
				margin: 0 0 30px 0;
			}

			.card__content-inner {
				width: 100%;
			}
		}
	}

}

.card--50-sm {
	position: relative;
	display: block;
	width: 100%;

	& > a {
		border: 0 !important;
	}

	a {
		display: block;
		///border: 0 !important;

		@include hover {

			.card__title {
				color: $blue;
			}

			.card__img {
				&:not(.card__img--wine) {
					@include overlay-custom(#000, 0.2);
				}
			}
		}
	}

	.card__img {
		position: relative;
		width: 100%;
		
		&:not(.card__img--wine) {
			margin: 0 0 20px 0;
			@include overlay-custom(#000, 0);
			&:after {
				padding-top: (2 / 3) * 100%;
				position: relative;
				display: block;
				content: '';
			}
		}
		&--wine {
			margin: 0 0 35px 0;
			img {
				display: block;
				width: auto;
				height: 244px;
				max-width: 70px;
				object-fit: contain;
				object-position: bottom;
			}
		}
	}

	.card__title {
		@include fluid(font-size, 900px, 1500px, 16px, 18px);
		//@include font-size(18);
		@include bold;
		margin: 0 0 20px 0;
		transition: color 200ms;

		em {
			color: $blue;

			span {
				color: #dedede;
			}
		}
	}

	.card__meta-item {
		position: relative;
        padding: 0 0 15px 0;
		margin: 0 0 12px 0;

		&:after {
			@include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
			background-image: -moz-linear-gradient(to right, #515151 20%, rgba(255, 255, 255, 0) 0%);
			position: absolute;
			width: 100%;
			height: 1px;
			left: 0;
			bottom: 0;
			content: '';
		}

		&:last-child {
			@include karla;

			&:after {
				content: none;
			}

			span {
				@include font-size(14);
				@include reg;
				display: block;
			}

			a {
				@include font-size(14);
				position: absolute;
				top: 0;
				width: 80px;
			}
		}
	}
	.card__meta-item--varietal {
		@include breakpoint(501px, 0) {
			margin: 0;
			padding: 0 0 10px;
			dd {
				min-height: 55px;
				margin-top: -5px;
			}
			&:after {
				content: none;
				display: none;
			}
		}
	}
	.card__meta-item--stack {
		dt,
		dd {
			width: 100%;
			display: block;
		}
	}

    a:not(.btn):not(.link) {
        color: inherit;
        transition: border 200ms;
        border-bottom: 1px solid $black;

        @include hover {
            border-color: transparent;
        }
    }

    dl {
		display: block;
		margin: 0;
        //margin: 0 0 45px 0;
    }

    dt,
    dd {
        display: inline-block;
    }

    dt {
        @include bold;
        @include karla;
		@include fluid(font-size, 900px, 1500px, 14px, 16px);
        //margin: 0 60px 0 0;
        width: 125px;
    }

    dd {
        @include font-size(18);
        margin: 0;
	}

	// if in a search result

	.search-grid & {
		.card__meta-item {
			position: relative;
			display: flex;
			justify-content: space-between;

			&:last-child a {
				position: relative;
			}
		}

		dt {
			//width: auto;
		}
	}

	@include breakpoint(0, 500px) {
		.card__title {
			font-size: 18px !important;
			font-size: 1.8rem !important;
		}
	}
}

.card--wine-award {
	//
}

.card--search {
	position: relative;
	display: block;
	width: 100%;

	.card__img {
		@include overlay-custom(#000, 0);
		position: relative;
		width: 100%;
		margin: 0 0 20px 0;

		&:after {
			padding-top: (2 / 3) * 100%;
			position: relative;
			display: block;
			content: '';
		}
	}

	.card__title {
		@include fluid(font-size, 700px, 1500px, 18px, 20px);
		@include bold;
		margin: 0 0 20px 0;
	}

	.card__content {
		@include serif;
		@include fluid(font-size, 700px, 1500px, 16px, 18px);
		margin: 0 0 10px 0;
	}

	.link {
		@include karla;
		@include bold;
		@include font-size(16);
		color: $black;
		transition: color 200ms;
	}

	a {
		display: block;
		border: 0 !important;
		color: $black;

		@include hover {

			.card__img {
				@include overlay-custom(#000, 0.2);
			}

			.link {
				color: rgba($black, 0.5);
			}
		}
	}
}

.closed {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-align: center;
    background: rgba(0,0,0,0.5);
    color: #FFF;
    justify-content: center;
    font-family: Karla,"Helvetica Neue",Helvetica,Arial,sans-serif;
    font-weight: bold;
}

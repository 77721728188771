// Page Article
// --------------------------------------------------
.page--article {
    main.content {
        padding-top: 0 !important;
    }

    .panel--logo-gallery {
        border-top: 2px solid #000;
        border-bottom: 0;
        margin-bottom: 0 !important;
    }
}

.article-hero {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    padding: 0 30px;

    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-top: (105 / 138) * 100%;
    }

    &:after {
        position: absolute;
        height: 200px;
        right: calc(25% - 40px);
        bottom: 0;
        width: 150vw;
        z-index: 5;
        background: #fff;
        content: '';
    }
    
    &__img {
        position: absolute;
        min-height: 300px;
        height: 100%;
        width: calc(100% + 300px);
        left: -150px;  
        top: 0;
    }

    &__sponsor {
        @include karla;
        @include font-size(14);
        @include bold;
        position: absolute;
        left: 30px;
        bottom: 275px;
        z-index: 5;
        color: rgba(#fff, 0.5);
    }
    
    &__caption {
        @include karla;
        @include font-size(14);
        @include bold;
        position: absolute;
        left: 30px;
        bottom: 220px;
        z-index: 5;
        color: rgba(#fff, 0.5);
    }

    @include breakpoint(0, 1700px) {
        &__img {
            width: 100%;
            left: 0;
        }
    }

    @include breakpoint(0, 1400px) {
        &:after {
           right: 310px;
        }
    }

    @include breakpoint(0, 800px) {                   
        &:after {
            content: none;
        }

        &__img {
            position: absolute;
            min-height: 0;
            height: 100%;
            width: 100%;
            left: 0;  
            top: 0;
        }

        &__caption {
            width: calc(100% - 60px);
            right: auto;
            left: 30px;
            bottom: 30px;
        }

        &__sponsor {
            bottom: 30px;
            top: auto;
        }
    }

}

.news-article,
.page-content  {
    @include fluid(padding-bottom, 700px, 1500px, 40px, 100px);

    &__content-inner {
        @include fluid(margin-top, 801px, 1500px, -120px, -100px);

        @include breakpoint(0, 800px) {
            margin-top: 0;
        }
    }

    .link {
        @include fluid(margin-top, 700px, 1500px, 45px, 75px);
    }

    .social-links {
        margin: 0;
	    padding: 0;
        list-style: none;
        position: relative;
        top: 4px;
        
        a {
            border: 0 !important;
            margin: 0 8px 0 0;
            width: 25px;
            height: 25px;
            
            &:before {
                font-size: 25px;
                color: #000;
            }

            @include hover {
                &:before {
                    color: $blue;
                }
            }

        }
    }

    &__meta-title {
        @include fluid(font-size, 700px, 1500px, 16px, 20px);
        @include fluid(margin-top, 700px, 1500px, 40px, 75px);
        margin-bottom: 40px;
    }

    &__related {
        @include karla;
        @include bold;
        @include fluid(font-size, 700px, 1500px, 14px, 16px);

        span {
            @include fluid(font-size, 700px, 1500px, 12px, 14px);
            display: block;
            margin: 0 0 5px 0;
        }

        a {
            @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
            display: block;
            padding: 0 0 25px 0;
            margin: 0 0 22px 0;
            color: #231f20;
            border: 0 !important;
            transition: color 200ms !important;

            @include hover {
                color: rgba($black, 0.5);
            }
        }
    }

    &__top-link {
        @include karla;
        @include bold;
        @include fluid(font-size, 700px, 1500px, 14px, 16px);

        a {
            @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
            display: block;
            padding: 0 0 15px 0;
            margin: 0 0 15px 0;
            border: 0 !important;
            transition: color 200ms !important;
            display: block;

            @include hover {
                color: $blue;
            }
        }

        em {
            display: inline-block;
            color: $blue;

            span {
                color: #dedede;
            }
        }
    }

    &__copy--post {
        margin-top: 50px;
    }

    @include breakpoint(0, 800px) {
        padding-top: 60px;
    }
}

.page--video {

    .banner {

        &--internal {
            @include fluid(padding-bottom, 700px, 1500px, 35px, 35px);
        }
    }

    .article-hero {
        max-width: 1340px;
        padding: 0 0 0 0;
        
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 0;
        }

        &:before {
            padding-top: (9 / 16) * 100%;
        }

        &:after {
            display: none;
        }

        &__sponsor {
            bottom: 50px;
        }

        &__img {
            left: 0;
            width: 100%;
        }

        .btn {
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1000;
            transform: translate(-50%,-50%);
        }

        a:hover {
            .btn {
                border-color: transparent;
                background: #fff;
                color: #1a1a1d;
            }

            .article-hero__img:before {
                position: absolute;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.2);
                top: 0;
                left: 0;
                content: '';
            }
        }
    }

    .news-article {
        @include fluid(padding-top, 700px, 1500px, 0px, 0px);
        padding-bottom: 40px;
        max-width: 900px;

        &__content-inner {
            margin-top: 40px;

            @include breakpoint(0, 800px) {
                margin-top: 0;
            }
        }
    }
}
// Page Awards
// --------------------------------------------------
.page--awards,
.page--wine-awards {
    main.content {
        padding-top: 0 !important;
    }
}

.page--awards-single {
    .panel--logo-gallery {
        border-top: 2px solid #000;
        border-bottom: 0;
        margin-bottom: 0 !important;
    }
}

.panel--awards-featured {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 140px);

    .page--awards-single & {
        @include fluid(padding-top, 700px, 1500px, 60px, 140px);
    }

    .panel__header {
        margin-bottom: 50px;
    }

    .panel__title {
        @include fluid(font-size, 700px, 1500px, 22px, 30px);
    }

    .featured-grid {
        @include flex-grid(2, 20px, 20px,'.featured-grid__item');
        @include fluid(margin-top, 700px, 1500px, -60px, -160px);
        position: relative;
        z-index: 5;
    }

    .standard-grid {
        @include flex-grid(3, 20px, 20px,'.standard-grid__item');
        position: relative;
        z-index: 5;
        justify-content: center;
    }

    @include breakpoint(0, 900px) {
        .standard-grid {
            @include flex-grid(2, 20px, 20px,'.standard-grid__item');
        }
    }

    @include breakpoint(0, 600px) {
        .standard-grid {
            @include flex-grid(1, 20px, 20px,'.standard-grid__item');
        }
        .featured-grid {
            @include flex-grid(1, 20px, 20px,'.featured-grid__item');
        }
    }

}

.panel--award-gallery {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 140px);

    .panel__header {
        position: relative;
        padding: 0 10%;
        @include fluid(margin-bottom, 700px, 1500px, 40px, 100px);

        .btn {
            display: block;
            margin-bottom: 20px;
        }
    }

    .header__content {
        width: 100%;
        max-width: 700px;
    }

    .header__buttons {
        position: absolute;
        right: 12%;
        top: 50%;
        transform: translateY(-50%);
    }

    .header__title {
        @include fluid(font-size, 700px, 1500px, 20px, 30px);
        margin: 0 0 15px 0;
    }

    .header__copy {
        @include fluid(font-size, 700px, 1500px, 16px, 20px);
    }

    .gallery-grid {
        @include flex-grid(3, 20px, 20px,'.gallery-grid__item');
        position: relative;
        z-index: 5;
    }

    @include breakpoint(0, 1350px) {
        .panel__header {
            padding-left: 0;

            .header__buttons {
                right: 0;
            }
        }

        .header__content {
            width: calc(100% - 270px);
        }
    }



    @include breakpoint(0, 800px) {
        .panel__header {
            padding: 0;

            .header__buttons {
                position: relative;
                right: auto;
                margin-top: 30px;
                transform: none;
            }

            .btn {
                display: inline-block;
                margin-bottom: 10px;

                &:first-child {
                    margin: 0 10px 10px 0;
                }
            }
        }

        .header__content {
            width: 100%;
            max-width: none;
            margin: 0 0 40px 0;
        }

        .gallery-grid {
            @include flex-grid(1, 0, 20px,'.gallery-grid__item');
        }
    }

}

.panel--about-awards {
    @include fluid(padding-top, 700px, 1500px, 60px, 140px);
    @include fluid(padding-bottom, 700px, 1500px, 60px, 160px);
    background-color: #191919;
    background-image: url(../img/ui/awards-about-bg.jpg);
    background-position: bottom right;
    color: #fff;

    &.no-team-rows {
        padding-bottom: 0;
    }

    .panel__intro {
        @include fluid(padding-bottom, 700px, 1500px, 40px, 100px);
        display: flex;
    }

    .panel__col {
        flex-basis: auto;
        width: 50%;
    }

    .panel__title {
        @include fluid(font-size, 700px, 1500px, 35px, 55px);
        position: relative;
        padding-right: 30px;

        span {
            @include fluid(font-size, 700px, 1500px, 16px, 20px);
            display: block;
        }

        &:after {
			@include icons;
            @include fluid(font-size, 700px, 1500px, 120px, 240px);
			position: absolute;
			color: rgba(#fff, 0.10);
			content: "\E014";
            top: 50%;
            transform: translateY(-50%);
            left: 15%;
		}
    }

    .panel__copy {
        @include fluid(font-size, 700px, 1500px, 16px, 22px);
        margin: 0 0 50px 0;
    }

    @include breakpoint(0, 650px) {
        .panel__intro {
            display: block;
        }

        .panel__col {
            width: 100%;
            margin: 0 0 30px 0;
        }
    }

}

.team-row {
    &__title {
        @include fluid(font-size, 700px, 1500px, 16px, 20px);
        position: relative;
        margin: 0 0 40px 0;

        &:after {
            @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 5px, $size: 1px);
            position: absolute;
            right: 0;
            top: 50%;
            height: 1px;
            width: calc(100% - 230px);
            content: '';
        }
    }

	&__grid {
        @include flex-grid(6, 20px, 20px,'.team-row__grid-item');
        margin-bottom: 50px;
    }

    @include breakpoint(0, 1100px) {
        &__grid {
            @include flex-grid(4, 20px, 20px,'.team-row__grid-item');
        }
    }

    @include breakpoint(0, 900px) {
        &__grid {
            @include flex-grid(3, 20px, 20px,'.team-row__grid-item');
        }
    }

    @include breakpoint(0, 650px) {
        &__grid {
            @include flex-grid(2, 20px, 20px,'.team-row__grid-item');
        }
    }

    @include breakpoint(0, 400px) {
        &__grid {
            @include flex-grid(1, 0, 20px,'.team-row__grid-item');
        }
    }

}

.panel--awards-logos {
    @include fluid(padding-top, 700px, 1500px, 60px, 140px);
    @include fluid(padding-bottom, 700px, 1500px, 60px, 160px);

    .panel__header {
        @include fluid(margin-bottom, 700px, 1500px, 60px, 100px);
        text-align: center;
    }

    .panel__header-title {
        @include fluid(font-size, 700px, 1500px, 22px, 30px);

        span {
            @include fluid(font-size, 700px, 1500px, 16px, 20px);
            display: block;
            margin: 0 0 10px 0;
        }
    }

    .panel__grid {
        @include flex-grid(4, 0, 100px,'.panel__grid-item');
        justify-content: space-evenly;
        align-items: center;

        @include breakpoint(0, 1100px) {
            @include flex-grid(4, 0, 100px,'.panel__grid-item');
            justify-content: space-evenly;
            align-items: center;
        }

        @include breakpoint(0, 900px) {
            @include flex-grid(3, 0, 100px,'.panel__grid-item');
            justify-content: space-evenly;
            align-items: center;
        }

        @include breakpoint(0, 600px) {
            @include flex-grid(2, 0, 50px,'.panel__grid-item');
            justify-content: space-evenly;
            align-items: center;
        }

        &.panel__grid--lg {
            @include flex-grid(2, 0, 50px,'.panel__grid-item');
            justify-content: space-evenly;
            align-items: center;

            @include breakpoint(600px, 0) {
                @include flex-grid(2, 0, 100px,'.panel__grid-item');
                justify-content: space-evenly;
                align-items: center;
            }

            @include breakpoint(900px, 0) {
                @include flex-grid(3, 0, 100px,'.panel__grid-item');
                justify-content: space-evenly;
                align-items: center;
            }

            .panel__grid-item > a {
                max-width: 200px;

                @include breakpoint(900px, 0) {
                  max-width: 100%;
                }
            }
        }
    }

    .panel__grid-item {
        display: flex;
        align-items: center;
        text-align: center;
        justify-content: center;
        padding: 0 50px !important;

        > a {
            display: block;
            width: 100%;
            max-width: 100px;

            @include breakpoint(900px, 0) {
              max-width: 100%;
            }
        }

        .logo-image {
            width: 100%;
            display: block;
            background-image: inherit;
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;

            &:after {
                content: "";
                display: block;
                padding-bottom: percentage(60 / 100);

                @include breakpoint(900px, 0) {
                    padding-bottom: percentage(95 / 315);
                }
            }
        }

        @include breakpoint(0, 1000px) {
            padding: 0 40px !important;
        }
        @include breakpoint(0, 800px) {
            padding: 0 30px !important;
        }
        @include breakpoint(0, 600px) {
            padding: 0 10px !important;
        }
    }

}

// single award

.winner-profile {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 160px);
    position: relative;

    &__header {
        position: relative;
    }

    &__img {
        position: relative;
        left: 45%;
        width: calc(65% + 5vw);
        top: -60px;
        z-index: 5;

        &:before {
            display: block;
            content: "";
            width: 100%;
            padding-top: (2 / 3) * 100%;
        }

        .winner-profile__meta {
            position: absolute;
            width: 280px;
            right: calc(5vw + 30px);
            top: calc(100% + 85px);
        }
        @include breakpoint(1150px, 1349px) {
            top: -95px;
        }
        @include breakpoint(1350px, 0) {
            top: -130px;
        }
    }

    &__content {
        display: flex;
        justify-content: space-between;
    }

    &__content-inner {
        @include fluid(margin-top, 700px, 1500px, 45px, -280px);
        @include fluid(padding-top, 700px, 1500px, 45px, 90px);
        flex-basis: auto;
        width: 65%;
        background: #fff;
        position: relative;
        z-index: 10;
        padding: 0 8.5% 0 0;
    }

    &__title {
        @include fluid(font-size, 700px, 1500px, 30px, 40px);
        //@include fluid(bottom, 800px, 1500px, 25%, 45%);
        transform: translateY(-50%);
        position: absolute;
        width: 35%;
        top: 40%;
        //top: 18%;
        z-index: 50;

        span {
            @include fluid(font-size, 700px, 1400px, 14px, 16px);
            display: block;
            margin: 10px 0 0 0;
        }

        /*
        &:before {
			@include icons;
            @include fluid(font-size, 700px, 1500px, 120px, 150px);
            @include fluid(top, 700px, 1500px, -160px, -200px);
			position: absolute;
			color: rgba($black, 0.20);
			content: "\E014";
        }
        */

        .logo {

            display: block;
            height: auto;
            margin-bottom: 1em;

            @include fluid(width, 700px, 1500px, 120px, 150px);

            path {

                fill: rgba($black, 0.20);

            }

        }

        @include breakpoint(0, 1500px) {
            // top: 60%;
        }

        @include breakpoint(0, 1200px) {
            // top: 75%;
        }

        @include breakpoint(0, 800px) {
            top:  auto;
            transform: none;
        }
    }

    &__copy {
        p {
            @include fluid(font-size, 700px, 1500px, 16px, 22px);

            &:first-child {
                @include fluid(font-size, 700px, 1500px, 18px, 26px);
            }
        }

        & + .btn {
            margin: 35px 0 0 0;
        }
    }

    &__meta {
        width: 280px;
        flex-basis: auto;
        position: relative;
    }

    &__meta-item {
        @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 5px, $size: 1px);
        padding: 0 0 25px 0;
        margin: 0 0 22px 0;

        a {
            color: inherit;
            transition: border 200ms;
            border-bottom: 1px solid $black;

            @include hover {
                border-color: transparent;
            }
        }
    }

    dl {
        display: block;
    }

    dt,
    dd {
        display: inline-block;
    }

    dt {
        @include bold;
        @include karla;
        @include font-size(16);
        //margin: 0 60px 0 0;
        width: 135px;
    }

    dd {
        @include font-size(18);
        margin: 0;
    }

    @include breakpoint(0, 1800px) {
        &__img {
            width: 65%;
        }
    }

    @include breakpoint(0, 1100px) {
        &__img {
            .winner-profile__meta {
                //width: 250px;
            }
        }
    }

    @include breakpoint(0, 800px) {
        &__title {
            position: relative;
            top: auto;
            widows: 100%;
            margin: 0 0 30px 0;
        }

        &__img {
            position: relative;
            width: 100%;
            left: 0;
        }

        &__content {
            padding: 0;
        }

        &__meta-mob {
            display: block;
            width: 200px;
        }

        dt {
            width: 100px;
        }

        &__content-inner {
            padding: 0;
            width: calc(100% - 310px);
        }

        &__title {
            margin-top:  0;

            &:before {
                content: none;
            }
        }
    }

    @include breakpoint(0, 650px) {
        &__content {
            display: block;
        }

        &__content-inner {
            width: 100%;
            margin: 0 0 40px 0;
        }

        &__meta {
            width: 100%;
            max-width: none;
        }
    }
}

.blockquote {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 100px);
    @include fluid(margin-bottom, 700px, 1500px, 60px, 140px);
    position: relative;

    &:after {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 1px;
        width: calc(100% - 15%);
        @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 8px, $size: 2px);
        content: '';
    }

    blockquote {
        @include bold;
        @include karla;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: row-reverse;
        margin: 0;

        &:before {
            position: absolute;
            top: -10px;
            left: 0;
            font-size: 50px;
            content: ' " ';
        }

        p {
            @include fluid(font-size, 700px, 1500px, 20px, 35px);
            width: 100%;
            max-width: 60%;
            line-height: 1.3;
        }

        cite {
           @include fluid(font-size, 700px, 1500px, 14px, 16px);
           font-style: normal;
           align-self: flex-end;

           span {
               display: block;
               color: #9c9c9c;
           }
        }
    }

    @include breakpoint(0, 600px) {
        blockquote {
            display: block;
            padding: 0 0 0 50px;

            p {
                margin: 0 0 30px 0;
                max-width: none;
            }
        }
    }
}

.winner-gallery {
    @include fluid(padding-bottom, 700px, 1500px, 40px, 100px);

    &--l-p,
    &--p-l {
        display: flex;
        justify-content: space-between;
    }

    &--l-p {
        .winner-gallery__portrait {
            .winner-gallery__caption {
                margin-left:  auto;
                text-align: right;
            }
        }
    }

    &--p-l {
        .winner-gallery__landscape {
            .winner-gallery__caption {
                margin-left:  auto;
                text-align: right;
            }
        }
    }

    &--l-sm {
        .winner-gallery__landscape {
            width: 85%;
            margin-left: auto;

            .winner-gallery__caption {
                margin-left:  auto;
                text-align: right;
            }
        }
    }

    &--l-sm-la {
        .winner-gallery__landscape {
            width: 85%;
            margin-right: auto;

            .winner-gallery__caption {
                margin-right:  auto;
                text-align: left;
            }
        }
    }

    &--l {
        .winner-gallery__landscape {
            width: 100%;
            margin-left: auto;

            .winner-gallery__caption {
                margin-left:  auto;
                text-align: right;
            }
        }
    }

    &__caption {
        @include font-size(16);
        color: rgba($black, 0.5);
        width: 100%;
        max-width: 350px;

        p {
            max-width: 350px;
        }
    }

    &__landscape {
        width: 58%;
        align-self: flex-end;

        &-img {
            padding-top: (45 / 68) * 100%;
            margin: 0 0 25px 0;
        }

    }

    &__portrait {
        width: 35%;

        &-img {
            padding-top: (121 / 80) * 100%;
            margin: 0 0 25px 0;
        }
    }

    @include breakpoint(0, 650px) {
        padding-bottom: 0 !important;

        &--l-p,
        &--p-l {
            display: block;
        }

        &--l-sm {
            .winner-gallery__landscape {
                width: 100%;
            }
        }

        &__landscape {
            width: 100%;
            padding-bottom: 40px;
        }

        &__portrait {
            width: 100%;
            padding-bottom: 40px;
        }
    }


}

.panel--finalists {
    @include fluid(padding-top, 700px, 1500px, 60px, 160px);
    @include fluid(padding-bottom, 700px, 1500px, 60px, 160px);
    color: #fff;
    background-color: #19191c;
    background-image: url(../img/ui/finalists-bg.jpg);
    background-position: right bottom;

    .panel__header {
        @include fluid(margin-bottom, 700px, 1500px, 40px, 80px);
        position: relative;

        &:after {
			@include icons;
            @include fluid(font-size, 700px, 1500px, 120px, 200px);
            @include fluid(margin-left, 700px, 1500px, -60px, -100px);
			position: absolute;
			color: rgba(#fff, 0.10);
			content: "\E014";
            top: 50%;
            transform: translateY(-50%);
            //left: 50%;
		}
    }

    .panel__title {
        @include fluid(font-size, 700px, 1500px, 22px, 30px);
        margin: 0 0 20px 0;

        &--past {
            @include dottedBorder($color: #515151, $orientation: horizontal, $position: top, $spacing: 8px, $size: 1px);
            @include fluid(padding-top, 700px, 1500px, 30px, 80px);
            @include fluid(margin-bottom, 700px, 1500px, 40px, 70px);
        }
    }

    .panel__copy {
        @include fluid(font-size, 700px, 1500px, 16px, 22px);
        width: 100%;
        max-width: 550px;
    }

    .finalists-grid {
        @include flex-grid(3, 20px, 40px,'.finalists-grid__item');
        @include fluid(padding-bottom, 700px, 1500px, 40px, 70px);
        position: relative;

        &--past {
            @include flex-grid(4, 20px, 0,'.finalists-grid__item');
            margin-bottom: 0 !important;
            padding-bottom: 0 !important;

            &:after {
                content: none;
            }
        }

        &:last-child {
            padding-bottom: 0 !important;
        }
    }

    @include breakpoint(0, 900px) {
        .finalists-grid {
            @include flex-grid(2, 20px, 40px,'.finalists-grid__item');
        }
    }

    @include breakpoint(0, 600px) {
        .finalists-grid {
            @include flex-grid(1, 0, 40px,'.finalists-grid__item');
        }
    }


}

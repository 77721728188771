// Contact form
// --------------------------------------------------

.gform_wrapper,
.payment-form, // @monk Just thought I'd add a set of these classes with a naming convention that makes more sense for the payment form
.contact-form {
	@include font-size(16);
	@include karla;
	@include bold;
	@include dottedBorder($color: $black, $orientation: horizontal, $position: bottom, $spacing: 8px, $size: 2px);
	@include fluid(padding-bottom, 700px, 1500px, 40px, 90px);

	form {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		position: relative;
		z-index: 10;
		width: 100%;
	}
	
	&__field {
		position: relative;
		margin: 0 0 20px 0;
		width: calc(50% - 10px);
		flex-basis: auto;
	}

	&__field--full {
		width: 100%;
	}

	.gfield_contains_required :is( .gform-grid-col, .ginput_container:not(.ginput_container_select, .ginput_complex) ),
	&__field--required:not(.payment-form__field--list) {

		@debug &;

		&:after {
			position: absolute;
			right: 30px;
			top: 23px;
			color: #fff;
			z-index: 10;
			content: '•';
		}
	}

	&__field--category,
	&__field--select {

		&::after {
			content: none;
		}

	}

	&__field--hidden {

		display: none !important;

	}
	
	:not([class*=checkbox]) > label:not(.error, .gfield_label, .gform-field-label) {
		@include hide;
	}
	
	.gform_drop_area,
	.control:not(select),
	input,
	textarea {

		&:not([type=checkbox], [type=submit]) {

			background: $black;
			border-radius: 0;
			border: 0;
			height: 65px;
			line-height: 1;
			padding: 0 15px;
			transition: background 200ms;
			width: 100%;
			color: #fff;
	
			&::-webkit-inner-spin-button, 
			&::-webkit-outer-spin-button { 
			  -webkit-appearance: none; 
			  margin: 0; 
			}
			
			&.focus,
			&:focus {
				background: lighten($black, 10);
			}
			
			&::-webkit-input-placeholder {
				transition: color 200ms;
				color: #fff;
			}
			
			&:-moz-placeholder {
				transition: color 200ms;
				color: #fff;
			}
			
			&::-moz-placeholder {
				transition: color 200ms;
				color: #fff;
			}
			
			&:-ms-input-placeholder {  
				transition: color 200ms;
				color: #fff;
			}
			
			&:focus::-webkit-input-placeholder { 
				color: #fff; 
			}
			
			&:focus:-moz-placeholder { 
				color: #fff;  
			}
			
			&:focus::-moz-placeholder { 
				color: #fff;
			}
			
			&:focus:-ms-input-placeholder { 
				color: #fff;  
			}

		}
		
	}

	.credit-card {

		display: flex;
		align-items: stretch;
		flex-direction: column;
		justify-content: center;
		padding-right: 60px;

	}

	input[type=number] {
		-moz-appearance:textfield;
	}

	[type=checkbox] {

		+ label {

			padding-left: calc(20px + 1rem);

			&::before {

				content: "";
				display: block;
				position: absolute;
				height: 20px;
				width: 20px;
				// transform: translateY(-25%);
				background: $black;
				transition: background 200ms;
				left: 0;
				top: 0;

			}

		}

		&:checked + label::before {

			background: $color_2;

		}

	}
	
	textarea.control,
	textarea {
		padding: 25px !important;
		height: 220px !important;
		resize: none;
	}
	
	.ginput_container_select,
	.select--styled { 
	    @include font-size(15);
	    overflow:hidden;
		margin:0; 
		width: 100%;
		padding: 0 15px;
		height: 65px;
		position: relative;
		background: $black url(../img/ui/select-arrow.svg) no-repeat center right 25px;
		cursor: pointer;
		transition: background 200ms;

		&:after {
			position: absolute;
			width: 1px;
			height: 25px;
			right: 65px;
			top: 20px;
			background: #5f5f61;
			z-index: 10;
			content: '';
		}
	}
	
	:is(.select--styled, .ginput_container_select) select { 
	    width:125%; 
	    background-color:transparent; 
	    background-image:none; 
	    -webkit-appearance: none; 
	    border:none; 
	    box-shadow:none; 
	    outline: none;
	    padding: 15px 0;
		color: #fff;
		height: 65px;
	}
	
	.select--styled select:focus {
		outline: none;
		color: #fff;
		background-color: transparent;
	}

	&__footer {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;

		span {
			@include font-size(14);
			color: rgba($black, 0.4);
		}
	}
	
	[type=submit],
	button {
		@include font-size(16);
		padding: 12px 30px;
		margin: 20px 0 0 0;
		display: inline-block;
	}

	.form-error,
	.select.error ~ label.error,
	textarea.error:focus ~ label.error,
	input.error:focus ~ label.error {
		border: 1px solid #dedede;

		&:before {
			border-color: #ededed transparent transparent;
			border-width: 8px;
			margin-left: -8px;
		}
	}

	@include breakpoint(0, 900px) {
		&__field {
			width: 100%;
		}
	}

	@include breakpoint(0, 400px) {
		&__footer {
			display: block;
		}

		button {
			display: block;
			margin: 30px auto 0;
			width: 180px;
		}
	}
}
// Page Front
// --------------------------------------------------
// .page-front {

// }

.home-grid {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 145px);

    &__row {
        @include flex-grid(2, 40px, 40px,'.home-grid__col');
    }

    &__row--landscape-stacked {
        .home-grid__col:nth-child(2) {
            .home-grid__item {
                @include fluid(padding-left, 700px, 1500px, 30px, 70px);
                height: 100%;
                padding-top: (31 / 22) * 100%;
            }
        }
    }

    &__row--landscape-stacked-rev {
        .home-grid__col:nth-child(1) {
            .home-grid__item {
                @include fluid(padding-right, 700px, 1500px, 30px, 70px);
                height: 100%;
                padding-top: (31 / 22) * 100%;

                .card--article-stacked {
                    right: auto;
                    left: 0;
                }
            }
        }

        .home-grid__col:nth-child(2) {
            .home-grid__item:first-child {
                margin: 0 0 20px 0;
            }
        }
    }

    &__row--half-half {
        .home-grid__item {
            height: 100%;
        }

        .card--article-list {
            a {
                padding-right: 50%;
                padding-left: 0;
            }

            .card__img {
                left: auto; 
                right: 0;
            }
        }
    }

    // &__col {
    //     height: 300px;
    // }

    &__item {
        display: flex;
        flex-direction: column;
        //background: #efefef;
    }

    .home-grid__item {
        position: relative;
    }

    .item__header {
        @include fluid(padding-right, 700px, 1500px, 30px, 70px);
        margin: 0 0 80px 0;
    }

    .item__title {
        @include bold;
        @include karla;
        @include fluid(font-size, 700px, 1500px, 35px, 65px);
        margin: 0 0 50px 0;

        & + p {
            @include fluid(font-size, 900px, 1500px, 18px, 26px);
        }
    }

    @include breakpoint(0, 675px) {
        &__row {
            @include flex-grid(1, 0, 20px,'.home-grid__col');
        }

        &__row--landscape-stacked {
            .home-grid__col:nth-child(2) {
                .home-grid__item {
                    position: static;
                }
            }
        }

        .card--article-stacked {
            padding-top: (34 / 25) * 100%;

            .card__img {
                height: calc(100% - 110px);
            }
        }

    }

}

.panel--featured-picks {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 145px);

    .container {
        max-width: 1100px;
    }

    .panel__header {
        @include fluid(margin-bottom, 700px, 1500px, 40px, 100px);
    }

    .header__title {
        @include fluid(font-size, 700px, 1500px, 35px, 55px);
       @include bold;
        display: inline-block;
        margin: 0 80px 0 0;

        @include breakpoint(0, 650px) {
            display: block;
            margin-bottom: 20px;
        }
    }

    .header__meta {
        @include bold;
        @include karla;
        color: rgba($black, 0.5);
    }
}

.home-video {

    text-align: center;
    //max-width: 980px;
    margin: auto;

    @include bold;
    @include karla;
    @include fluid(font-size, 900px, 1500px, 18px, 26px);
    @include fluid(padding-bottom, 700px, 1500px, 60px, 145px);

    &--embed {

        @include fluid(margin-top, 700px, 1500px, 40px, 100px);
        position: relative;
		padding-bottom: 56.25%; /* 16:9 */
		height: 0;

		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

    }
    
}

#featured-sponsor {

    background-image: var(--bg);
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    pointer-events: none;

    @include breakpoint(0, 480px) {

        background-position: 50%;

    }

    .container {

        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        gap: 1em;
        padding: 0;

        @include breakpoint(0, 480px) {

            flex-direction: column;

        }

        img {

            width: auto;
            height: clamp(2em, 7vw, 15em);
            max-width: 380px;

            @include breakpoint(0, 480px) {

                width: auto;
                height: auto;
                max-height: 140px;

            }

        }

        &::after {

            content: none;

        }

    }

    a {

        &::before {

            content: "";
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;

        }

    }

}

.box {
    &-container {
        background-color: black;
        padding: 4em 0;
    }
    &-wrapper{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        max-width: 1400px;
        margin: 0 auto;
        gap: 2.5em;
        padding: 0 2em;

        @include breakpoint(0, 650px) {
            grid-template-columns: 1fr;
        }
    }
    &-items {
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;
        border-radius: 13px;
        border: 1px solid white;

        position: relative;

        a {
            position: relative;
            z-index: 1;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;

            background: linear-gradient(179deg, rgba(0, 0, 0, 0.00) -10.14%, rgba(0, 0, 0, 0.52) 72.57%, rgba(0, 0, 0, 0.69) 86.14%);
        }

        &:hover {
            .box-title {
                padding-bottom: 10px;
                &::after {
                    width: 100%;
                }
            }
        }
    }
    &-inner-wrapper {
        height: 355px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;

        color: white;
        font-family: Karla;
    }
    &-category {
        font-size: 1.6rem;
        font-weight: 500;
        margin-bottom: -.7em;
    }
    &-title {
        font-size: 4rem;
        font-weight: 500;
        margin: 0;
        position: relative;
        transition: all ease .2s;

        &::after {
            content: '';
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 0%;

            border-bottom: 1px solid white;
            transition: all ease .2s;
        }
    }
}

.home {
    &-ads {
        background-color: black;
        .panel--promotion {
            margin-bottom: 100px;
        }
    }
    &-breakout {
        background-color: black;
        padding-bottom: 100px;

        .container{
            display: flex;
            grid-gap:3vw;

            @include breakpoint(0, 680px) {
                flex-direction: column;

                img{
                    width: 100%;
                }
            }
            
        }

        img {
            margin-bottom: 30px;
            width: 50%;
            object-fit: cover;
        }
        h2 {
            font-size: clamp(3.5rem, 5vw, 6.4rem);
            font-weight: 500;
            letter-spacing: 0;
            height: max-content;
        }
        h2, h3, p {
            color: white;
        }
        .step-up {
            opacity: 1;
        }
        .panel__content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            > * { max-width: 550px; }
        }
        .panel__copy {
            margin-bottom: 3rem;
        }
        .content-right {
            padding-top: 2rem;
        }
    }
    @include breakpoint(0, 680px) {
        &-ads {
            > .container {
                padding: 0;
            }
        }
    }

    video{
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.panel-homefront {
    background: black;
    color: white;
    padding-bottom: 100px !important;
    .post-wrapper {
        display: flex;
        flex-wrap: wrap;
        column-gap: 2%;
        row-gap: 50px;

        &:not(:last-child) {
            margin-bottom: 50px;
        }
    }
    .step-up {
        margin-bottom: 0 !important;
    }
    .card {
        width: 32%;
        &:nth-child(4), &:nth-child(5) {
            width: 49%;
        }
        h3 {
            color: white;
            font-size: 2.5rem;
            font-weight: 500;
        }
        span {
            text-transform: uppercase;
            font-weight: 300;
        }
        &--news {
            display: flex;
            background: black; 
            border-radius: 20px;
            border: 1px solid white;
            overflow: hidden;

            .title-wrapper {
                height: 100%;
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                min-height: 150px;

                border-top: 1px solid white;
            }
            a {
                height: 100%;
                width: 100%;
                display: flex;
                flex-direction: column;
            }
            .cover {
                margin: 0;
            }
            height: 100%;
        }
        &--article {
            border-radius: 20px;
            border: 1px solid white;
            overflow: hidden;
        }
    }
    .card--article-dark {
        background: black;
        .card__img:after {
            background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.27) 16.5%, #000 84%);
        }
    } 
}
@media only screen and (max-width: 1000px) {
    .panel-homefront {
        .card {
            &, &:nth-child(4), &:nth-child(5) {
                width: 49%;
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .panel-homefront {
        .card {
            &, &:nth-child(4), &:nth-child(5) {
                width: 100%;
            }
        }
    }
}
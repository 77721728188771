// Hamburger
// --------------------------------------------------

.hamburger {
	width: 60px;
	height: 60px;
	transition: 500ms ease-in-out;
	cursor: pointer;
	position: absolute;
	top: 0;
	right: 0;
	background: #030204;
	padding: 20px;
	z-index: 50;
  
	.hamburger__box {
		position: absolute;
		width: 25px;
		left: 19px;
		top: 21px;
	}
	
	span:not(.hamburger__box) {
		display: block;
		position: absolute;
		height: 2px;
		width: 100%;
		background: #fff;
		opacity: 1;
		left: 0;
		transform: rotate(0deg);
		transition: 250ms ease-in-out;
		
		&:nth-child(1) {
		  top: 0px;
		}
		
		&:nth-child(2),
		&:nth-child(3) {
		  top: 7px;
		}
		
		&:nth-child(4) {
		  top: 14px;
		}
		
		// variations 
		
		.is-samadara & {
			background: #fff;
		}
	}
	
	.menu-open & {
		span:not(.hamburger__box) {
			&:nth-child(1) {
			  top: 6px;
			  width: 0%;
			  left: 50%;
			}
			
			&:nth-child(2) {
			  transform: rotate(45deg);
			}
			
			&:nth-child(3) {
			  transform: rotate(-45deg);
			}
			
			&:nth-child(4) {
			  top: 6px;
			  width: 0%;
			  left: 50%;
			}

		}
	}

}
/*! Humaan DNA v2.0 */

// Utilities
@import "_utilities/normalize";
@import "_utilities/mixins";
@import "_utilities/clearfix";
@import "_utilities/helpers";

// Globals
@import "_globals/variables";
@import "_globals/wp-core-style";

// Vendor
@import "_vendor/slick";
@import "_vendor/modaal";

// Type
@import "fonts/type";
@import "fonts/icons";

// Components
@import "_components/buttons";
@import "_components/forms";
@import "_components/banner";
@import "_components/search";
@import "_components/social-links";
@import "_components/hamburger";
@import "_components/mobile-nav";
@import "_components/cards";
@import "_components/panels";
@import "_components/rating";
@import "_components/contact-form";
@import "_components/gravityforms";
@import "_components/modals";
@import "_components/sponsor";
@import "_components/waypoints";
@import "_components/block--event";

// Layouts
@import "_sections/header";
@import "_sections/header--dropmenu";
@import "_sections/footer";
@import "_sections/layout";
@import "_sections/cms-content";
@import "_sections/page-front";
@import "_sections/page-awards";
@import "_sections/page-restaurant";
@import "_sections/page-wine";
@import "_sections/page-article";
@import "_sections/page-news";
@import "_sections/page-50";
@import "_sections/page-standard";
@import "_sections/page-stays";
@import "_sections/page-contact";
@import "_sections/page-search";
@import "_sections/whats-on";
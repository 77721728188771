// Page Article
// --------------------------------------------------
.page--news {
    main.content {
        position: relative;
        padding-top: 0 !important;
    }

    .panel--logo-gallery {
        border-top: 2px solid #000;
        border-bottom: 0;
        margin-bottom: 0 !important;
    }

    .panel--news {
       padding-bottom: 30px !important;
    }

    .panel--news + .panel--subscribe  {
        @include fluid(margin-bottom, 700px, 1500px, 30px, 80px);    
    }
}

.news-feature {
    position: relative;
    margin: 0 auto;
    width: 100%;
    //max-width: 1400px;
    min-height: 640px;
    max-height: 1000px;
    height: 60vw;

    .container {
        position: relative;
        height: 100%;
    }
    
    &__img {
        position: absolute;      
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &__content {
        @include fluid(bottom, 800px, 1500px, 30px, 90px);
        @include fluid(font-size, 800px, 1500px, 16px, 20px);
        @include karla;
        @include bold;
        position: absolute;
        left: 30px;
        z-index: 10;
        color: #fff;
        padding-right: 30px;

        a:not(.btn) {
            color: #fff;

			@include hover {
				color: rgba(#fff, 0.5);
			}
        }

        p {
            margin: 0 0 50px 0;
        }

        @include breakpoint(0, 800px) {
            bottom: 160px;
        }
    }
    
    &__title {
        @include fluid(font-size, 800px, 1500px, 30px, 55px);
        margin: 0 0 20px 0;
        max-width: 800px;

        span {
            @include fluid(font-size, 800px, 1500px, 16px, 20px);
            display: block;
            margin: 0 0 15px 0;
        }
    }

    &__sponsor {
        @include fluid(bottom, 800px, 1500px, 30px, 90px);
        @include karla;
        @include font-size(14);
        @include bold;
        position: absolute;
        right: 30px;
        z-index: 10;
        color: rgba(#fff, 0.5);

        @include breakpoint(0, 800px) {
        }
    }

    @include breakpoint(0, 900px, 'height') {
        height: 100vh;
    }

    @include breakpoint(0, 1050px) {
        height: 50vw;
    }

    @include breakpoint(0, 650px) {                   
        &__img {
            @include overlay-custom(#000, 0.3);
        }
    }

}

.news-nav {
    @include dottedBorder($color: #0d0d0d, $orientation: horizontal, $position: bottom, $spacing: 8px, $size: 2px);
    @include fluid(margin-bottom, 700px, 1500px, 30px, 80px);
    @include font-size(16);
    @include karla;
    @include bold;

    .menu-trigger {
        display: none;
    }
    
    ul {
        display: flex;
    }

    li {
        margin: 0 50px 0 0;
    }

    a {
        position: relative;
        padding: 30px 0;
        display: inline-block;
        color: $black;

        &:before {
            @include center;
            position: absolute;
            bottom: 0;
            height: 2px;
            width: 0;
            transition: width 200ms ease-out;
            background: #34343a;
            content: '';
        }

        @include hover {
            &:before {
                width: 100%;
            }
        }
        
        &.active:before {
            background: #34343a;
            width: 100%;

            @include hover {
                &:before {
                    background: #34343a;
                    width: 100%;
                }
            }
        }
    }

    @include breakpoint(0, 450px) {
        .menu-trigger {
            padding: 30px 0;
            display: block;

            &:after {
                @include icons;
                font-size: 6px;
                content: "\E007";
                margin: 0 0 0 15px;
                transition: transform 200ms;
            }

            &.is--active {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
        
        .menu-region {
            transition: all 400ms ease-out;
            max-height: 0;
            overflow: hidden;
            display: block;
            text-align: left;

            li {
                margin: 0;
                
                a {
                    display: block;
                    padding: 15px 0;

                    &.active {
                        color: $blue;

                        &:before {
                            content: none;
                        }
                    }

                    @include hover {
                        &:before {
                            content: none;
                        }
                    }
                }

                &:first-child {
                    a {
                        padding-top: 0;
                    }
                }

                &:last-child {
                    margin-bottom: 10px;
                }
            }
            
            &.is--open {
                max-height: 500px;
            }
        }
    }
}

.news-lower {
    position: relative;
    display: block;
    background: #fff;
    z-index: 10;
}
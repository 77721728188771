// Banners
// --------------------------------------------------

.banner {
	position: relative;
	background: $black;
	overflow: hidden;
	color: #fff;

	&__content {
		position: relative;
		z-index: 5;
		padding: 0 30px 100px;
        max-width: 1400px;
        margin-top: auto;
        width: 100%;
	}

	&__img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;

        &:has(video)::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            top: 0;
            right: 0;
            bottom: 0;
            opacity: .6;
            pointer-events: none;

            background-image: linear-gradient(rgba(black, 1) 0%, rgba(black, 0) 40%, rgba(black, 1) 100%);
        }
	}

	&--home {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 85vh;
		color: #fff;
		text-align: center;
		min-height: 700px;

		.logo-large {
			display: none;
		}

		.banner__title {
			@include fluid(font-size, 600px, 1500px, 45px, 64px);
			@include bold;
			margin: 0 0 10px 0;
            text-align: start;
            letter-spacing: -.03em;
            font-weight: 500;
            max-width: 665px;
            line-height: 85%;
		}

		.banner__subtitle {
			@include serif;
			@include fluid(font-size, 600px, 1500px, 16px, 28px);
			margin: 0 0 30px 0;
		}

		.banner__buttons {
			margin-left: -15px;

			.btn {
				margin-left: 15px;
				margin-bottom: 10px;
				background: rgba(#fff, 1);
				color: #000;

				&:hover{
					opacity: 0.5;
				}
			}
		}
		.banner__img {
			background-position: center bottom;


			> iframe {

				width: 100%;
				// height: 56.25vw;
				min-height: 100%;
				// min-width: 177.77vh;

				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				opacity: 0;

			}

		}

		@include breakpoint(0, 900px) {
			height: 600px;
			min-height: 0;
			//display: block;
			text-align: center;

			.logo-large {
				display: inline-block;
				width: 140px;
				height: 140px;
				margin: 0 0 10px 0;
			}

			.banner__content {
				@include center;
				//position: absolute;
				//bottom:150px;
			}

			.banner__img {
				@include overlay-custom(#000, 0.5);
			}
			.banner__buttons {
				.btn {
					@include font-size(14);
				}
			}

		}

	}

	&--internal {
		@include fluid(padding-top, 700px, 1500px, 60px, 140px);
		@include fluid(padding-bottom, 700px, 1500px, 60px, 140px);
		//padding: 0 0 240px;

		.banner__title {
			@include fluid(font-size, 600px, 1500px, 24px, 65px);
			@include bold;
			margin: 0 0 30px 0;
			//max-width: 700px;

			span {
				@include fluid(font-size, 700px, 1500px, 16px, 20px);
				padding: 0 0 30px 0;
				margin: 0  0 30px 0;
				width: 100%;
				max-width: 400px;
				@include dottedBorder($color: #fff, $orientation: horizontal, $position: bottom, $spacing: 8px, $size: 2px);
				display: block;
			}
		}

		.banner__copy {
			@include serif;
			@include fluid(font-size, 600px, 1500px, 18px, 26px);
			margin: 0 0 40px 0;
			width: 100%;
			max-width: 1000px;
		}

		.banner__content {
			padding: 0;
		}
	}
	
	&--footer {

		padding: 0;

	}

	.page--wine-awards & {
		@include fluid(padding-bottom, 700px, 1500px, 140px, 240px);
		
		&.banner--internal .container {
			display: flex;
			align-items: flex-end;
		}
		.banner__content {
			flex: none;
			max-width: calc(66.666% - 100px);
			@include breakpoint(0, 1023px) {
				max-width: calc(66.666% - 50px);
			}
			@include breakpoint(0, 900px) {
				max-width: 75%;
			}
		}
		.banner__sponsored-by {
			position: relative;
			z-index: 5;
			margin: 0 0 -40px auto;
			color: rgba(#fff, 0.5);
			font-weight: 600;
			@include font-size(14);
			@include karla;
			img {
				display: block;
				margin: 0 0 3px 20px;
				max-width: 100px;
				height: auto;
			}
			@include breakpoint(1024px, 0) {
				display: flex;
				align-items: flex-end;
			}
			@include breakpoint(0, 1023px) {
				text-align: center;
				img {
					max-width: 80px;
					margin: 15px auto 0;
				}
			}
		}
	}
	.single-award & {
		.banner__btn-wrapper {
			@include breakpoint(0, 800px) {
				margin-bottom: 20px;
			}
		}
	}
	.page--awards & {
		@include fluid(padding-bottom, 700px, 1500px, 140px, 240px);

		.container {
			position: relative;
		}

		.banner__img {
			background-position: bottom right;
		}

		.banner__btn-wrapper {
			.btn {
				display: inline-block;
			}
		}

		.banner__content {
			max-width: calc(100% - 240px);
		}

		.logo-large {
			position: absolute;
			top: calc(50% - 100px);
			height: 200px;
			width: 200px;
			right: 30px;
			z-index: 20;

			path {
				fill: #c7c7c7;
			}
		}

		.banner__title {
			max-width: 700px;
		}

		@include breakpoint(0, 900px) {
			.logo-large {
				top: calc(50% - 60px);
				width: 120px;
				height: 120px;
			}
		}

		.banner__location {
			@include karla;
			@include font-size(16);
			color: #fff;
			display: inline-block;
			margin: 0 0 0 35px;

			&:before {
				@include icons;
				font-size: 24px;
				margin: 0 20px 0 0;
				content: "\E009";
			}
		}

		@include breakpoint(0, 600px) {
			.banner__btn-wrapper {
				text-align: center;

				.btn {
					display: block;
					margin: 0 auto 20px;
					max-width: 250px;
				}
			}

			.banner__location {
				display: block;
				margin: 0;
			}

			.banner__content {
				max-width: none;
			}

			.logo-large {
				display: none;
			}
		}
	}

	.page--awards-single & {
		@include fluid(padding-bottom, 750px, 1500px, 100px, 140px);

		.container {
			position: relative;
		}

		.banner__sponsor {
			@include karla;
			@include font-size(14);
			@include bold;
			position: absolute;
			right: 30px;
			bottom: 30px;
			z-index: 5;
			color: rgba(#fff, 0.5);
		}

		@include breakpoint(0, 1100px) {
			.banner__title,
			.banner__copy {
				max-width: calc(100% - 220px);
			}
		}

		@include breakpoint(0, 800px) {
			.banner__title,
			.banner__copy {
				max-width: none;
			}

			.banner__sponsor {
				position: relative;
				right: auto;
				bottom: auto;
			}
		}
	}

	.page--restaurant & {
		@include fluid(padding-top, 700px, 1500px, 30px, 90px);
		@include fluid(padding-bottom, 700px, 1500px, 20px, 65px);
		background: #fff;
		color: $black;
		overflow: hidden;

		.banner__shape {
			position: absolute;
			top: 52%;
			right: 18%;
			width: 800px;
			height: 670px;
			z-index: 1;
		}

		.banner_content {
			z-index: 5;
		}

		.banner__title {
			@include fluid(font-size, 681px, 1500px, 22px, 60px);
			margin: 0;

			em {
				display: inline-block;
				color: $blue;

				span {
					@include fluid(font-size, 600px, 1500px, 24px, 60px);
					color: #dedede;
					display: inline-block;
					width: auto;
					padding: 0;
					margin: 0;
				}
			}
		}

		.link--next {
			position: absolute;
			right: 0;
			bottom: 0;
		}

		@include breakpoint(0, 680px) {
			.link--next {
				display: none;
			}
		}

		@include breakpoint(0, 500px) {
			.banner__title {
				max-width: 175px;
			}
		}
	}

	.page--article & {
		@include fluid(padding-top, 700px, 1500px, 30px, 90px);
		@include fluid(padding-bottom, 700px, 1500px, 20px, 65px);
		background: #fff;
		color: $black;
		overflow: hidden;

		.banner__shape {
			position: absolute;
			top: 41%;
			right: 20%;
			width: 800px;
			height: 670px;
			z-index: 1;
		}

		.banner_content {
			z-index: 5;
		}

		.banner__title {
			@include fluid(font-size, 600px, 1500px, 24px, 60px);
			width: 100%;
			max-width: 980px;
			margin: 0;

			span {
				@include fluid(font-size, 700px, 1500px, 14px, 16px);
				margin: 0 0 15px 0;
				padding: 0;
				display: block;
			}

		}
	}

	.page--news & {
		@include center;
		@include fluid(top, 700px, 1500px, 60px, 140px);
		position: absolute;
		background: none;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		z-index: 5;
		width: 100%;
		max-width: 1400px;

		.banner__title span {
			max-width: 400px;
		}

		.banner__sponsor {
			@include karla;
			@include font-size(14);
			@include bold;
			z-index: 5;
			color: rgba(#fff, 0.5);
		}

		@include breakpoint(0, 650px) {
			.banner__sponsor {
				img {
					width: 120px;
				}
			}
		}
	}

	.page--50 & {
		@include fluid(padding-bottom, 700px, 1500px, 40px, 80px);
		background: #fff;
		color: $black;

		.banner__content {
			max-width: calc(100%  - 240px);
		}

		.banner__copy {
			margin: 0;
		}

		.container {
			position: relative;
		}

		&--footer {

			padding-bottom: 0;

		}

		.logo-large {
			position: absolute;
			top: calc(50% - 100px);
			height: 200px;
			width: 200px;
			right: 30px;

			path {
				fill: #0a0a0a;
			}
		}

		@include breakpoint(0, 900px) {
			.logo-large {
				top: calc(50% - 60px);
				width: 120px;
				height: 120px;
			}
		}

		@include breakpoint(0, 600px) {
			.banner__content {
				max-width: none;
			}

			.logo-large {
				display: none;
			}

		}

	}

	.page-template-template-payment-form &,
	.page--standard & {
		@include fluid(padding-bottom, 750px, 1500px, 60px, 100px);
		background: none;
		color: $black;

		.banner__title {
			margin: 0;
		}
	}

	.page--contact & {
		@include fluid(padding-bottom, 700px, 1500px, 40px, 80px);
		background: none;
		color: $black;

		.banner__title {
			margin: 0;
		}
	}

	.page--search & {
		@include fluid(padding-bottom, 700px, 1500px, 40px, 80px);
		background: #030204;

		.banner__title {
			@include fluid(font-size, 700px, 1500px, 18px, 20px);
			margin: 0;
		}
	}

	&__scroll {
		@include center;
		position: absolute;
		bottom: 50px;
		animation: bob 2s infinite ease-in-out;
		z-index: 5;

		&:before {
			@include icons;
			font-size: 20px;
			color: #fff;
			content: "\E007";
		}
	}

}

@keyframes bob {
	0% {
	  -webkit-transform: translateY(-8px);
	  transform: translateY(-8px);
	}

	50% {
	  -webkit-transform: translateY(0);
	  transform: translateY(0);
	}

	100% {
	  -webkit-transform: translateY(-8px);
	  transform: translateY(-8px);
	}
  }

.category.page--news {
	.banner--internal {
		padding-bottom: 30px !important;
	}
}

.pum-container{
	background: url(../img/ui/footer-bg.jpg) !important;
    background-position: bottom right !important;
}	

.pum-content, .pum-title{
	font-size: 1.6rem;
    font-family: Karla,"Helvetica Neue",Helvetica,Arial,sans-serif !important;

	input[type=email] {
		font-size: 16px;
		font-size: 1.6rem;
		font-family: Karla,"Helvetica Neue",Helvetica,Arial,sans-serif;
		border-bottom: 1px solid #fff;
		height: 65px;
		background: 0 0;
		color: #fff;
		padding: 0 115px 0 0;
	}

	label{
		span{

				font-size: 14px;
				display: block;
				margin: 5px 0 0 0;
			
		}
	}

	button {
		font-weight: 700;
		font-family: Karla,"Helvetica Neue",Helvetica,Arial,sans-serif;
		position: absolute;
		right: 0;
		bottom: 20px;
		z-index: 5;
		background: 0 0;
		opacity: .5;
		-webkit-transition: opacity .2s ease;
		transition: opacity .2s ease;

		span:after {
			font-family: Icons;
			display: inline-block;
			vertical-align: middle;
			line-height: 1;
			font-weight: 400;
			font-style: normal;
			speak: none;
			text-decoration: inherit;
			text-transform: none;
			text-rendering: optimizeLegibility;
			-webkit-font-smoothing: antialiased;
			-moz-osx-font-smoothing: grayscale;
			content: "";
			color: #fff;
			font-size: 20px;
			margin: 0 0 0 15px;
			-webkit-transition: -webkit-transform .2s;
			transition: -webkit-transform .2s;
			transition: transform .2s;
			transition: transform .2s,-webkit-transform .2s;
		}
	}
}
// Form styles
// --------------------------------------------------

input:focus::-webkit-contacts-auto-fill-button{
    opacity: 0;
}

legend,
label {
	margin-bottom: 10px;
	display: block;
	position: relative;
}

input,
textarea {
	-webkit-appearance: none;
	border: 0;
	line-height: 1;
	width: 100%;
	display: block;
	position: relative;
	//padding: 15px 20px;
	border-radius: 0;
}

button {
	border-radius: 0;
}

// --------------------- Error styles

form {
	label.error {
		opacity: 0;
		bottom: 105%;
		visibility: hidden;
		position: absolute;
		transition:
			opacity $timing linear,
			bottom $timing linear;

		&.form-error {

			opacity: 1;
			visibility: visible;
	
		}

	}
	
	.form-error,
	.select.error ~ label.error,
	textarea.error:focus ~ label.error,	
	input.error:focus ~ label.error,
	ul:empty ~ .error {
		@include font-size(10);
		right: -16px;
		bottom: 100%;
		z-index: 12;
		padding: 10px;
		border-radius: 5px;
		background-color: white;
		width: 80px;
		margin-right: -8px;
		text-align: center;
		opacity: 1;
		visibility: visible;
		margin-bottom: -2px;
		color: $black;
		font-weight: 300;
		
		// Arrow
		&:before {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: ' ';
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(white, 0);
			border-width: 7px;
			margin-left: -7px;
		}
		
		&:after {
			top: 100%;
			left: 50%;
			border: solid transparent;
			content: " ";
			height: 0;
			width: 0;
			position: absolute;
			pointer-events: none;
			border-color: rgba(white, 0);
			border-top-color: #fff;
			border-width: 6px;
			margin-left: -6px;
		}
	}
	
}

.subscribe-form__label {

	@include bold;
	@include karla;
	@include fluid(font-size, 800px, 1500px, 18px, 20px);
	position: relative;
	margin: 0 0 20px 0;

	span {
		@include fluid(font-size, 700px, 1500px, 14px, 16px);
		display: block;
		margin: 5px 0 0 0;
	}

}

.subscribe-form__field {
	position: relative;
	width: 100%;
	height: 65px;

	button {
		@include bold;
		@include karla;
		position: absolute;
		right: 0;
		top: 18px;
		z-index: 5;
        background: none;
        opacity: 0.5;
        transition: opacity 200ms ease;
		
		span {
			position: relative;
			
			&:after {
				@include icons;
				content: "\E011";
				color: #fff;
				font-size: 20px;
				margin: 0 0 0 15px;
				transition: transform 200ms;
			}
		}

		@include hover {
            opacity: 1;

			span:after {
				transform: translateX(3px);
			}
		}
    }

	input[type="email"] {
		@include font-size(16);
		@include karla;
		border-bottom: 1px solid #fff;
		height: 65px;
		background: none;
		color: #fff;
		padding: 0 115px 0 0;

		&::-webkit-input-placeholder {
			color: #fff;
			transition: color 200ms;
		}
		
		&:-moz-placeholder {
			color: #fff;
			transition: color 200ms;
		}
		
		&::-moz-placeholder {
			color: #fff;
			transition: color 200ms;
		}
		
		&:-ms-input-placeholder {
			color: #fff;
			transition: color 200ms;
		}
		
		&:focus::-webkit-input-placeholder {
			color: rgba(#fff, 0.5);	
		}

		&:focus:-moz-placeholder {
			color: rgba(#fff, 0.5);
		}

		&:focus::-moz-placeholder {
			color: rgba(#fff, 0.5);
		}

		&:focus:-ms-input-placeholder {
			color: rgba(#fff, 0.5);
		}
	}

	.content & {

		[type=email] {

			color: inherit;
			border-bottom-color: currentColor;

			&::-webkit-input-placeholder {
				color: $black;
			}
			
			&:-moz-placeholder {
				color: $black;
			}
			
			&::-moz-placeholder {
				color: $black;
			}
			
			&:-ms-input-placeholder {
				color: $black;
			}

			&:focus::-webkit-input-placeholder {
				color: rgba($black, 0.5);	
			}
	
			&:focus:-moz-placeholder {
				color: rgba($black, 0.5);	
			}
	
			&:focus::-moz-placeholder {
				color: rgba($black, 0.5);	
			}
	
			&:focus:-ms-input-placeholder {
				color: rgba($black, 0.5);	
			}

		}

		button span:after {
			color: $black;
		}

	}

}

// Page 50
// --------------------------------------------------
.page--search {

    main.content {
        padding-top: 0 !important;
    }

    .panel--logo-gallery {
        border-top: 2px solid #000;
        border-bottom: 0;
        margin-bottom: 0 !important;
        margin-top: 85px;
    }

    .banner {
	
        input {
            @include karla;
            @include bold;
            @include fluid(font-size, 700px, 1500px, 24px, 60px);
            background-color: #030204;
            border-radius: 0;
            border: 0;
            height: 80px;
            line-height: 1;
            padding: 0;
            transition: background 200ms;
            width: 100%;
            color: #fff;
            
            &:focus {
                //background: lighten($black, 10);
            }
            
            &::-webkit-input-placeholder {
                transition: color 200ms;
                color: #fff;
            }
            
            &:-moz-placeholder {
                transition: color 200ms;
                color: #fff;
            }
            
            &::-moz-placeholder {
                transition: color 200ms;
                color: #fff;
            }
            
            &:-ms-input-placeholder {  
                transition: color 200ms;
                color: #fff;
            }
            
            &:focus::-webkit-input-placeholder { 
                color: rgba(#fff, 0.5); 
            }
            
            &:focus:-moz-placeholder { 
                color: rgba(#fff, 0.5); 
            }
            
            &:focus::-moz-placeholder { 
                color: rgba(#fff, 0.5);
            }
            
            &:focus:-ms-input-placeholder { 
                color: rgba(#fff, 0.5);
            }
            
        }
    
    }

    @include breakpoint(0, 600px) {
        .banner {
            
            input {
                text-overflow: ellipsis;
                width: 100%;

                &::-webkit-input-placeholder {
                    text-overflow: ellipsis;
                    width: 90%;
                }
                
                &:-moz-placeholder {
                    text-overflow: ellipsis;
                    width: 90%;
                }
                
                &::-moz-placeholder {
                    text-overflow: ellipsis;
                    width: 90%;
                }
                
                &:-ms-input-placeholder {  
                    text-overflow: ellipsis;
                    width: 90%;
                }
            }
        }
    }

}

.search-grid {
    @include fluid(padding-top, 750px, 1500px, 40px, 80px);
    @include flex-grid(3, 65px, 60px,'.search-grid__item');

    @include breakpoint(0, 900px) {
         @include flex-grid(2, 30px, 60px,'.search-grid__item');
    }

    @include breakpoint(0, 600px) {
        @include flex-grid(1, 0, 30px,'.search-grid__item');
    }
}

.no-results {
    //padding: 60px 0;
}
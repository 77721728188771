// Page Restaurant
// --------------------------------------------------
.page--restaurant {
    main.content {
        padding-top: 0 !important;
    }

    .panel--logo-gallery {
        border-top: 2px solid #000;
        border-bottom: 0;
        margin-bottom: 0 !important;
    }
}

.restaurant-gallery {
    margin: 0 auto;
    width: 100%;
    max-width: 1400px;
    padding: 0 30px;
    overflow: visible;

    &__item {
        width: 100%;
        max-width: 1400px;
        margin: 0 10px;
    }

    &__img {
        padding-top: (85 / 128) * 100%;
        min-height: 250px;
    }

    &__nav {
        position: absolute;
        height: 130px;
        right: calc(25% - 40px);
        bottom: 0;
        width: 150vw;
        z-index: 5;

        &:after {
            position: absolute;
            width: 100%;
            height: 65px;
            right: 0;
            bottom: 0;
            background: #fff;
            content: '';
        }
    }

    .slick-list {
        overflow: visible !important;
    }

    .slick-arrow {
        position: absolute;
        width: 60px;
        height: 60px;
        top: 5px;
        background: #140c07;
        text-align: center;
        padding: 16px;
        font-size: 0;

        &:before {
            @include icons;
            font-size: 16px;
            color: #fff;
            transition: transform 200ms;
        }
    }

    .slick-prev {
        right: 61px;

        &:before {
            content: "\E00A";
        }

        @include hover {
            &:before {
                transform: translateX(-3px);
            }
        }
    }

    .slick-next {
        right: 0;

        &:before {
            content: "\E00B";
        }

        @include hover {
            &:before {
                transform: translateX(3px);
            }
        }
    }

    @include breakpoint(0, 1400px) {
        &__nav {
            right: 310px;
        }
    }

    @include breakpoint(0, 800px) {
        margin: 0 0 40px 0;

        &__nav {
            height: 60px;
            right: 40px;

            .slick-arrow {
                top: 0;
            }

            &:after {
                content: none;
            }
        }

        .slick-arrow {
            width: 40px;
            height: 40px;
            padding: 12px;
            top: auto;
            bottom: 0;

            &:before {
                font-size: 12px;
            }
        }

        .slick-prev {
            right: 41px;
        }
    }


}

.restaurant-profile,
.news-article,
.page-content {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 160px);
    position: relative;

    &__content {
        display: flex;
        justify-content: space-between;
    }

    &__content-inner {
        flex-basis: auto;
        width: 75%;
        background: #fff;
        position: relative;
        z-index: 10;
        padding: 0 8.5% 0 0;
    }

    &__title {
        @include fluid(margin-top, 700px, 1500px, 50px, -140px);
        @include fluid(margin-bottom, 700px, 1500px, 45px, 90px);
        @include fluid(font-size, 700px, 1500px, 30px, 40px);
        position: relative;

        span {
            @include fluid(font-size, 700px, 1500px, 14px, 16px);
            display: block;
            margin: 10px 0 0 0;
        }

        &:before {
			@include icons;
            @include fluid(font-size, 700px, 1500px, 120px, 150px);
            @include fluid(top, 700px, 1500px, -180px, -220px);
			position: absolute;
			color: rgba($black, 0.20);
			content: "\E014";
		}
    }

    &__copy {
        p {
            @include fluid(font-size, 700px, 1500px, 16px, 22px);

            &:first-child {
                @include fluid(font-size, 700px, 1500px, 18px, 26px);
            }
        }

        ul {
            @include fluid(font-size, 700px, 1500px, 16px, 22px);
        }

        & + .btn {
            margin: 35px 0 0 0;
        }
    }

    &__meta {
        @include fluid(padding-top, 700px, 1500px, 40px, 100px);
        width: 280px;
        flex-basis: auto;
        position: relative;

        dl {
            display: block;
            margin: 0 0 45px 0;
        }

        dt,
        dd {
            vertical-align: top;
            display: inline-block;
        }

        dt {
            @include bold;
            @include karla;
            @include font-size(16);
            //margin: 0 60px 0 0;
            width: 135px;
            margin-top: 3px;
        }

        dd {
            @include font-size(18);
            margin: 0;
        }

        a:not(.btn):not(.link) {
            color: $black;
            transition: border 200ms;
            border-bottom: 1px solid $black;

            @include hover {
                color: $gold;
                border-color: transparent;
            }
        }
    }

    &__meta-item {
        @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
        padding: 0 0 25px 0;
        margin: 0 0 22px 0;
    }

    aside .btn {
        margin-bottom: 45px;

        + .btn {
            margin-bottom: 0;
        }
    }

    @include breakpoint(0, 1400px) {
        &__content-inner {
            width:  calc(100% - 310px);
        }
    }

    @include breakpoint(0, 800px) {
        padding-top: 0;

        &__content {
            padding: 0;
        }

        &__meta {
            //padding-top: 0;
        }

        dt {
            width: 100px;
        }

        &__content-inner {
            //padding: 0;
            width: calc(100% - 230px);
        }
    }

    @include breakpoint(0, 680px) {

        &__content {
            display: block;
        }

        &__content-inner {
            width: 100%;
            margin: 0 0 40px 0;
            padding: 0;
        }

        &__meta {
            width: 100%;
            max-width: none;
        }
    }
}

.restaurant__meta {
    @include karla;
    @include font-size(14);
    line-height: 1.1;
    margin: 45px 0 0 0;
    display: block;

    strong {
        @include bold;
    }

    p + p {
        margin: 10px 0 0 0;
    }
}

.restaurant__uber-eats {

    .logo {
        width: 92px;
        margin-right: 20px;
        vertical-align: bottom;
    }

    span {
        @include font-size(15);
        @include karla;
        @include dottedBorder($color: #000, $orientation: horizontal, $position: bottom, $spacing: 5px, $size: 1px);
        display: inline-block;
        padding: 0 0 20px 0;
        font-weight: 700;
        vertical-align: bottom;
    }
}


.page-content__meta {
    padding-top: 0 !important;
}

.page--standard .news-article__meta-title:first-of-type {
    margin-top: 0;
}

.page-content--meta {
    padding-top: 0;
}

.panel--restaurant-gallery {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 160px);
}

.restaurant-grid {

    &--1-col {
        @include flex-grid(1, 0, 20px,'.restaurant-grid__item');

        .card--article-halfs {
            &:before {
                padding-top: (39 / 118) * 100%;
            }

            .card__content {
                @include fluid(padding-left, 650px, 1500px, 0, 110px);
            }
        }

        @include breakpoint(0, 650px) {
            .card--article-halfs {
                &:before {
                    padding-top: 72.41379%;
                }
            }
        }
    }

    &--2-col {
        @include flex-grid(2, 20px, 20px,'.restaurant-grid__item');

        @include breakpoint(0, 650px) {
            @include flex-grid(1, 0, 20px,'.restaurant-grid__item');
        }
    }

    .card.card--article-dark {

        &:before {
            padding-top: (39 / 58) * 100%;
        }

        .card__img {
            height: 100%;
        }
    }
}

.panel--news {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 140px);

    .panel__header {
        margin-bottom: 50px;
    }

    .panel__title {
        @include fluid(font-size, 700px, 1500px, 22px, 30px);
    }

    .news-grid {

    }

    .news-grid--1-col {
        @include flex-grid(1, 0, 60px,'.news-grid__item');

        @include breakpoint(0, 650px) {
            @include flex-grid(1, 0, 30px,'.news-grid__item');
        }
    }

    .news-grid--2-col {
        @include flex-grid(2, 20px, 60px,'.news-grid__item');

        @include breakpoint(0, 650px) {
            @include flex-grid(1, 0, 30px,'.news-grid__item');
        }
    }

    .news-grid--3-col {
        @include flex-grid(3, 20px, 60px,'.news-grid__item');

        @include breakpoint(0, 900px) {
            @include flex-grid(2, 20px, 60px,'.news-grid__item');
        }

        @include breakpoint(0, 600px) {
            @include flex-grid(1, 0, 30px,'.news-grid__item');
        }
    }

    &-footer {
        @include fluid(margin-bottom, 700px, 1500px, 50px, 200px);
        margin-top: 25px;
        text-align: center;
    }
}

.sponsor {
    max-width: 150px;
    max-height: 70px;

    &--inline {
        display: inline-block;
        margin: 0 0 -1px 10px;
    }

    &--card-award {
        max-width: 100px;
        max-height: 70px;
        position: absolute;
        left: 20px;
        bottom: 20px;
        opacity: 0.3;
        transition: opacity 200ms;
    }
}
// Box sizing
// --------------------------------------------------
*, *:before, *:after {
	box-sizing: border-box;
}
*:focus {
	outline: none;
}


// Highlighted
// --------------------------------------------------
::selection {
	color: white;
	background-color: rgba(0,0,0, 0.15);
}


// Page layout
// --------------------------------------------------

html {
	height: 100%;
	font-size: 62.5%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
}

body {
	@include font-size(16);
	font-family: $fontStack;
	line-height: 1.4;
	position: relative;
	background-color: #030204;
}


// Layout
// --------------------------------------------------

.page-container {
	width: 100%;
	min-height: 100vh;
	overflow: hidden;
	position: relative;
	transition: padding 400ms;
	z-index: 1;

	.page--internal & {
		padding: 80px 0 0 0;
	}

	.header--show-alt.page--internal & {
		padding: 140px 0 0 0;
	}

	@include breakpoint(0, 680px) {
		.page--internal & {
			padding: 60px 0 0 0;
		}
	}
}

.outer-container {
	// position: relative;
	// z-index: 5;
	// overflow: hidden;
	// width: 100%;

	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-y: scroll;
    overflow-x: hidden;
	transition: top 600ms cubic-bezier(0.165, 0.84, 0.44, 1), padding 400ms;
	background-color: #fff;
	-webkit-overflow-scrolling: touch;

	// &:before {
	// 	position: fixed;
	// 	width: 100%;
	// 	height: 100%;
	// 	background: rgba(#000, 0);
	// 	top: 0;
	// 	left: 0;
	// 	transition: background 600ms cubic-bezier(0.165, 0.84, 0.44, 1);
	// 	z-index: 100;
	// 	pointer-events: none;
	// 	content: '';
	// }
	
	.search-open & {
		top: 125px;

		// &:before {
		// 	background: rgba(#000, 0.8);
		// }
   }
	
	.page--internal & {
		padding: 80px 0 0 0;

		.tablet & {
			padding: 140px 0 0 0;
		}

	}

	.tablet .page--internal & {
		@include breakpoint(0, 680px) {
			padding: 60px 0 0 0;
		}
	}

	.header--show-alt.page--internal & {
		padding: 140px 0 0 0;
	}

	@include breakpoint(0, 900px) {
		.page--internal & {
			padding: 140px 0 0 0;
		}
	}

	@include breakpoint(0, 680px) {
		.page--internal & {
			padding: 60px 0 0 0;
		}
	}
}

.container {
	@include clearfix;
	margin-left: auto;
	margin-right: auto;
	width: 100%;
	max-width: 1400px;
	padding: 0 30px;
	
	&.full {
		max-width: none;
		padding: 0;
	}
}

main.content {
	//@include fluid(padding-top, 700px, 1500px, 60px, 0px);
	background: #fff;
}

.banner .banner__content.banner__content--animate,
.banner .banner__img, 
.news-feature__img {
	will-change: opacity, transform;
}

.page--error {
	.banner--home {
		flex-direction: column;
	}
}

.screen-reader-text {

	@include hide;

}
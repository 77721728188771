// Waypoints
// --------------------------------------------------
$sass-loop: 0 1 2 3 4 5 6 7 8 9 10 11 12 13 14 15 16 17 18 19 20 21 22 23 24 25 26 27 28 29 30;
// $waypoint-duration: 0.8s;

.step-in {
    opacity: 0;
    transition: opacity $waypoint-duration ease;
    will-change: opacity, transform;
    
    .waypoint-reveal & {
        opacity: 1;
    }
    
    @media screen and (max-width:800px) {
        opacity: 1 !important;
    }
}
    
.step-in-left {
    transition: opacity $waypoint-duration linear,
				transform $waypoint-duration ease;
	transform: translateX(-20px);
    opacity: 0;
    will-change: opacity, transform;
	
	.waypoint-reveal & {
		transition: opacity $waypoint-duration linear,
				transform $waypoint-duration ease;
		opacity: 1;
		transform: translate(0);
    }
    
    @media screen and (max-width:800px) {
        opacity: 1;
        transform: translateX(0);
    }
}

.step-in-right {
    transition: opacity $waypoint-duration linear,
				transform $waypoint-duration ease;
	transform: translateX(20px);
    opacity: 0;
    will-change: opacity, transform;
	
	.waypoint-reveal & {
		transition: opacity $waypoint-duration linear,
				transform $waypoint-duration ease;
		opacity: 1;
		transform: translate(0);
    }
    
    @media screen and (max-width:800px) {
        opacity: 1;
        transform: translateX(0);
    }
}

.step-out-right {
    transition: opacity $waypoint-duration linear,
				transform $waypoint-duration ease;
	transform: translateX(0px);
    opacity: 1;
    will-change: opacity, transform;
	
	.waypoint-reveal & {
		transition: opacity $waypoint-duration linear,
				transform $waypoint-duration ease;
		opacity: 0;
		transform: translate(50px);
    }
    
    @media screen and (max-width:800px) {
        opacity: 1;
        transform: translateX(0);
    }
}

.step-out-left {
    transition: opacity $waypoint-duration linear,
				transform $waypoint-duration ease;
	transform: translateX(0px);
    opacity: 1;
    will-change: opacity, transform;
	
	.waypoint-reveal & {
		transition: opacity $waypoint-duration linear,
				transform $waypoint-duration ease;
		opacity: 0;
		transform: translate(-50px);
    }

    @media screen and (max-width:800px) {
        opacity: 1;
        transform: translateX(0);
    }
}

.step-up {
    opacity: 0;
    transform: translateY(15px);
    transition: opacity $waypoint-duration linear,
                transform $waypoint-duration ease;
    will-change: opacity, transform;

    .waypoint-reveal & {
        opacity: 1;
        transform: translateY(0);
    }
    @media screen and (max-width:800px) {
        opacity: 1;
        transform: translateY(0);
    }
}

.step-up-fast {
    opacity: 0;
    transform: translateY(15px);
    transition: opacity $waypoint-duration linear,
                transform $waypoint-duration ease;
    will-change: opacity, transform;

    .waypoint-reveal & {
        opacity: 1;
        transform: translateY(0);
    }
    @media screen and (max-width:800px) {
        opacity: 1;
        transform: translateY(0);
    }
}

.step-down {
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity $waypoint-duration linear,
                transform $waypoint-duration ease;
    will-change: opacity, transform;

    .waypoint-reveal & {
        opacity: 1;
        transform: translateY(0);
    }
    @media screen and (max-width:800px) {
        opacity: 1;
        transform: translateY(0);
    }
}

.zoom-in {
    opacity: 0;
    transform: scale(0.2);
    transition: opacity $waypoint-duration linear, transform $waypoint-duration cubic-bezier(0.175, 0.885, 0.32, 1.275);
    will-change: opacity, transform;

    .waypoint-reveal & {
        opacity: 1;
        transform: scale(1);
    }
    @media screen and (max-width:800px) {
        opacity: 1;
        transform: scale(1);
    }
}



@for $i from 1 through length($sass-loop) {
    // for grouped items within a region.
    .step-in:nth-child(#{$i}),
    .step-in-left:nth-child(#{$i}),
    .step-in-right:nth-child(#{$i}),
    .step-up:nth-child(#{$i}),
    .step-down:nth-child(#{$i}) { transition-delay: $i * 0.15s !important; } 
    
    // for manual delay
    .step-in[data-delay="#{$i}"],
    .step-in-left[data-delay="#{$i}"],
    .step-in-right[data-delay="#{$i}"],
    .step-up[data-delay="#{$i}"],
    .step-down[data-delay="#{$i}"],
    .zoom-in[data-delay="#{$i}"] { transition-delay: $i * 0.15s !important; }

    //fast 
    .step-up-fast:nth-child(#{$i}) { transition-delay: $i * 0.05s !important; }
}
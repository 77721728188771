// Mobile nav
// --------------------------------------------------
.header-main__mobile-nav-wrapper {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	background: #000;
	display: none;
	z-index: 50;
	opacity: 0;
	opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;
    transition: transform 300ms linear, opacity 300ms;

	.logo-large {
		position: absolute;
		right: 45px;
		top: 45px;
		width: 120px;
		height: 120px;
	}

	.social-links {
		a {
			margin: 0 15px 0 0;

			&:before {
				color: #fff;
			}
		}
	}

	.menu-open & {
		transform: translateY(0);
		opacity: 1;
		pointer-events: all;
	}

	@include breakpoint(0, 680px) {
		display: block;
	}
}

.header-main__mobile-nav-inner {
	position: relative;
	padding: 45px;
	height: 100%;
	overflow: scroll;
}

.header-main__mob-nav {
	margin: 80px 0 0 0;

	a {
		@include karla;
		color: #fff;
	}
}

.header-main__menu-one {
	@include font-size(16);
	@include bold;
	padding: 0 0 25px 0;
	@include dottedBorder($color: #fff, $orientation: horizontal, $position: bottom, $spacing: 8px, $size: 1px);

	li {
		margin: 0 0 25px 0;
	}
}

.header-main__menu-two {
	@include font-size(14);
	margin: 45px 0;

	a {
		color: rgba(#fff, 0.6);
	}

	li {
		margin: 0 0 15px 0;
	}
}

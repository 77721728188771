@keyframes kenburns-full {
    from {
        transform: translateX(0%);
    }
  
    to {
        transform: translateX(-50%);
    }
}

.page--stays {
    main.content {
        padding: 0;
    }

    #disclaimer{
        display: none;
    }

    .slick-list{
        overflow: visible;

        img{
            margin: 0 0 0 20px;
        }
    }

    .banner {
        position: relative;
        height: 600px;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        color: white;
        background-color: black;
        .background-wrapper {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            //mix-blend-mode: hard-light;

            img {
                height: 100%;
                object-fit: cover;

                &:not(.looping) {
                    width: 100%;
                }
                &.looping {
                    object-position: 0% 50%;
                    animation: 600s infinite linear kenburns-full;
                }
            }
            &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;

                background: linear-gradient(178deg, rgba(0, 0, 0, 0.05) 1.4%, rgba(0, 0, 0, 0.04) 29.03%, rgba(0, 0, 0, 0.00) 114.16%);
            }
        }
        &__content {
            display: flex;
            justify-content: space-between;
            gap: 1em;
        }
        &__title {
            text-transform: uppercase;
            font-weight: 500;
            font-size: clamp(10rem, 11vw, 16rem);
            letter-spacing: -.4964rem;
            margin-bottom: -.22em !important;
        }
        @include breakpoint(0, 680px) {
            &__content {
                flex-direction: column;
                gap: 0;
                > h1:first-child {
                    margin-bottom: -0.47em !important;
                }
                > h1:last-child {
                    text-align: end;
                }
            }
            > .container {
                padding: 0;
            }
        }
    }
    .menu-trigger {
        @include breakpoint(0, 680px) {
            @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
            margin-bottom: 10px;
        }
    }
    .menu-region {
        justify-content: space-between;
        li {
            margin: 0;
            a {
                text-transform: uppercase;
                font-weight: 500;
                padding: 40px 0;
                transition: all ease .2s;

                &.active {
                    font-weight: 700;
                    &::before {
                        content: none;
                    }
                }
                &:hover {
                    opacity: .65;
                    &::before {
                        content: none;
                    }
                }
            }
        }
        @include breakpoint(0, 680px) {
            // display: grid;
            // grid-template-columns: repeat(3, 1fr);
            // padding: 40px 0;
            display: flex;
            flex-direction: column;
            // column-gap: 15px;
            // row-gap: 5px;
            li {
                // text-align: center;

                a {
                    padding: 0;
                    font-size: 1.5rem;
                }
            }
        }

    }
    .news-nav {
        background: none;

        .menu-region {
            @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
        }
    }
    .main-tax {
        font-family: 'Karla';
        text-transform: uppercase;
        color: black;
    }
    .post__title {
        font-size: clamp(4rem, 5vw, 6.2rem);
        font-weight: 500;
        letter-spacing: -.04em;
        margin-top: -7px;
        padding-bottom: 60px;
    }
    .slider-button-dock {
        display: flex;
        justify-content: space-between;
        gap: 6px;

        button {
            width: 100%;
            background-color: white;
            border: 1px solid black;
            font-family: "Karla";
            @include fluid(font-size, 700px, 1500px, 30px, 40px);
            font-weight: 500;
            padding: 10px;
        }
    }
}

.stays {
    &__container {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding: 0 0 100px;
    
        row-gap: 40px;
        column-gap: 25px;

        h2 {
            color: black;
            margin-bottom: .8em;
            font-weight: 500;
        }
        p, small {
            font-family: "Karla", "Helvetica Neue", Helvetica, Arial, sans-serif;
            color: black;
            text-transform: uppercase;
            font-size: 1.1rem;
        }

        @include breakpoint(0, 680px) {
            grid-template-columns: 1fr;
        }
    }
    &__list {
        border-radius: 20px;
        border: 1px solid black;
        overflow: hidden;

        > a {
            display: block;
        }

        &:hover {
            .stays__image {
                filter: brightness(.8);
            }
        }
    }
    &__content {
        padding: 1.2rem 2.3rem 2.3rem;

        &-details {
            display: flex;
            justify-content: space-between;

            &:has(.stays__content-cost) {
                > p {
                    max-width: 215px;
                }
            }

        }
        &-cost {
            text-align: end;
            // margin-top: -.7rem;
            display: flex;
            flex-direction: column;
        }
    }
    &__cost {
        font-size: 2.6rem !important;
        // margin-bottom: -.35em;
        margin-bottom: -.1em;
    }
    &__image, &-banner, &-gallery-img {
        width: 100%;
        object-fit: cover;
        height: 100%;
    }
    &-gallery-img {
        max-height: 800px;
    }
    &__image{
        max-height: 285px;
        aspect-ratio: 4/3;
        transition: all ease .2s;
    }
    &-banner {
        max-height: 520px;
    }

    &-content-img-block {
        display: flex;
        align-items: center;

        &-wrapper {
            margin-bottom: 50px;
            padding-bottom: 80px;
            @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
        }

        &__img {
            width: 100%;
            object-fit: cover;
            aspect-ratio: 1;
            max-width: 40%;
        }

        &__body {
            padding: 60px;
            p {
                @include fluid(font-size, 700px, 1500px, 16px, 19px);
                font-family: 'Karla';
    
                strong {
                    @include fluid(font-size, 700px, 1500px, 18px, 24px);
                }

                &+p:has(strong) {
                    margin-top: 60px;
                }
            }

        }

        & + .stays-content-img-block {
            margin-top: 60px;
        }

        &:nth-child(even) {
            flex-direction: row-reverse;
        }

        @include breakpoint(0, 680px) {
            &, &:nth-child(even) {
                flex-direction: column;
            }
            &__img {
                max-width: 100%;
            }
            &__body {
                padding: 20px 0;
                p+p:has(strong) {
                    margin-top: 40px;
                }
            }
        }
    }

    &-content-strip-block, &-profile__extra-notes {
        display: grid;
        grid-template-columns: 280px 1fr;
        gap: 40px;
        padding-bottom: 50px;
        margin: 0 auto 50px;

        p {
            @include fluid(font-size, 700px, 1500px, 16px, 19px);
            font-family: 'Karla';

            strong {
                @include fluid(font-size, 700px, 1500px, 18px, 24px);
            }
        }

        @include breakpoint(0, 680px) {
            grid-template-columns: 1fr;
            gap: 20px;
            // margin: 0 auto 30px;
            // padding-bottom: 30px;
        }
    }
    &-profile__extra-notes {
        padding: 62px 66px;
        border: 1px solid black;
        @include breakpoint(0, 680px) {
            padding: 20px;
        }
        p {
            margin: 0;
        }
    }
    &-content-strip-block {
        &:not(.second) {
            @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
        }
        &.second {
            margin-bottom: 80px;
            @include breakpoint(0, 680px) {
                margin-bottom: 40px;
            }
        }

        max-width: 1150px;
        p+p:has(strong) {
            margin-top: 60px;
        }
    }

    &-secondary-img {
        width: 100%;
        max-height: 388px;
        object-fit: cover;
        margin-bottom: 120px;

        @include breakpoint(0, 680px) {
            max-height: unset;
            aspect-ratio: 1;
            margin-bottom: 60px;
        }
    }
}


.stays-profile {
    @include fluid(padding-bottom, 700px, 1500px, 60px, 160px);
    position: relative;

    &__content {
        display: flex;
        justify-content: space-between;
        padding-bottom: 50px;
        margin-bottom: 50px;
        @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
    }

    &__content-inner {
        flex-basis: auto;
        // width: 75%;
        width: 80%;
        background: #fff;
        position: relative;
        z-index: 10;
        padding: 63px 5.5% 0;
        margin-top: -87px;
        @include breakpoint(0, 680px) {
            margin-top: 0px;
        }
    }

    &__title {
        @include fluid(margin-top, 700px, 1500px, 50px, -140px);
        @include fluid(margin-bottom, 700px, 1500px, 45px, 90px);
        @include fluid(font-size, 700px, 1500px, 30px, 40px);
        position: relative;

        span {
            @include fluid(font-size, 700px, 1500px, 14px, 16px);
            display: block;
            margin: 10px 0 0 0;
        }

        &:before {
			@include icons;
            @include fluid(font-size, 700px, 1500px, 120px, 150px);
            @include fluid(top, 700px, 1500px, -180px, -220px);
			position: absolute;
			color: rgba($black, 0.20);
			content: "\E014";
		}
    }

    &__copy {
        margin-bottom: 60px;
        h2 {
            margin-bottom: 1em;
            @include fluid(font-size, 700px, 1500px, 25px, 40px);
            font-weight: 500;
            letter-spacing: -.03em;
        }
        p {
            @include fluid(font-size, 700px, 1500px, 16px, 20px);
            font-family: 'Karla';

            &:first-child {
                @include fluid(font-size, 700px, 1500px, 18px, 26px);
            }
        }

        ul {
            @include fluid(font-size, 700px, 1500px, 16px, 22px);
        }

        & + .btn {
            margin: 35px 0 0 0;
        }
    }

    &__meta {
        // @include fluid(padding-top, 700px, 1500px, 40px, 100px);
        width: 280px;
        flex-basis: auto;
        position: relative;
        margin-top: -44px;
        @include breakpoint(0, 680px) {
            margin-top: 0px;
        }


        dl {
            display: block;
            margin: 0 0 45px 0;
        }

        dt,
        dd {
            vertical-align: top;
            display: inline-block;
        }

        dt {
            @include bold;
            @include karla;
            @include font-size(16);
            //margin: 0 60px 0 0;
            width: 135px;
            margin-top: 3px;
        }

        dd {
            @include font-size(18);
            margin: 0;
        }

        a:not(.btn):not(.link) {
            color: black;
            transition: border 200ms;
            border-bottom: 1px solid black;

            @include hover {
                color: $gold;
                border-color: transparent;
            }
        }

        .social-links a {
            &:before {
                color: black !important;
                width: 20px;
            }
            &:hover:before {
                color: #ceb04c !important;
            }
        }
    }

    &__meta-item {
        @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 4px, $size: 1px);
        padding: 0 0 25px 0;
        margin: 0 0 22px 0;
    }

    aside .btn {
        margin-bottom: 45px;

        + .btn {
            margin-bottom: 0;
        }
    }


    &__need-to-knows {
        display: flex;
        gap: 20px;

        > p {
            white-space: nowrap;
            font-family: 'Karla';
            @include fluid(font-size, 700px, 1500px, 18px, 24px);
        }
        > ul {
            margin: 0;
            @include fluid(font-size, 700px, 1500px, 16px, 18px);
            font-family: 'Karla';
        }

        @include breakpoint(0, 680px) {
            flex-direction: column;
        }
    }


    @include breakpoint(0, 1400px) {
        &__content-inner {
            width:  calc(100% - 280px);
        }
    }

    @include breakpoint(0, 800px) {
        padding-top: 0;

        &__content {
            padding: 0;
        }

        // &__meta {
        //     padding-top: 0;
        // }

        dt {
            width: 100px;
        }

        &__content-inner {
            //padding: 0;
            width: calc(100% - 230px);
        }
    }

    @include breakpoint(0, 680px) {

        &__content {
            // display: block;
            display: flex;
            flex-direction: column-reverse;
            padding-top: 10px;
        }

        &__content-inner {
            width: 100%;
            margin: 0 0 40px 0;
            padding: 20px 0;
        }

        &__meta {
            width: 100%;
            max-width: none;
        }
    }
}

.stays__meta {
    @include karla;
    @include font-size(14);
    line-height: 1.1;
    margin: 45px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 40px;

    @include breakpoint(0, 680px) {
        margin: 10px 0 0;
        padding: 20px;
    }

    border: 1px solid black;
    text-align: center;

    strong {
        @include bold;
    }

    p, a:not(.book-button) {
        margin: 0;
        text-transform: uppercase;
        text-decoration: none;
        border: none !important;
    }

    .social-links {
        display: flex;
        justify-content: center;
        gap: 30px;

        > a {
            margin: 0;
        }
        &::after {
            content: none;
        }
        &:has(+*) {
            background-position: bottom;
            background-image: linear-gradient(to right, #515151 25%, rgba(255, 255, 255, 0) 0%);
            background-size: 4px 1px;
            background-repeat: repeat-x;
            padding-bottom: 30px;
        }
    }

    .book-button {
        border-radius: 34px;
        border: 1px solid black;
        padding: 1rem 2.6rem;
        width: max-content;
        margin: 0 auto;

        &:hover {
            border: 1px solid #ceb04c !important;
        }
    }

    .special-code {
        text-decoration: underline !important;
    }
}

.page-content__meta {
    padding-top: 0 !important;
}

.page--standard .news-article__meta-title:first-of-type {
    margin-top: 0;
}

.page-content--meta {
    padding-top: 0;
}

.acf-map {
    width: 100%;
    height: 600px;
    // border: #ccc solid 1px;
    margin: 20px 0;
}
.acf-map img {
    max-width: inherit !important; 
}

.single-stays{
    .stays__content-cost{
        text-align: center;
    }
}
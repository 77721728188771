.block--event {
  background-position: bottom;
  background-image: linear-gradient(
    to right,
    #515151 25%,
    rgba(255, 255, 255, 0) 0
  );
  padding: 43px 0;
  background-size: 4px 1px;
  background-repeat: repeat-x;

  .block__inner {
    display: flex;
    margin-left: #{(-50 / 1200) * 100}vw;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -15px;

    @include breakpoint(1400px, 0) {
      flex-wrap: nowrap;
    }

    @include breakpoint(1200px, 0) {
      margin-left: -50px;
    }

    > div {
      padding-left: #{(50 / 1200) * 100}vw;
      margin-bottom: 15px;

      @include breakpoint(1200px, 0) {
        padding-left: 50px;
      }
    }
  }

  .block__date {
    font-size: 1.6rem;
    letter-spacing: #{(1.6 / 16)}em;
    color: rgba($black, 0.85);
    text-transform: uppercase;
    line-height: 1;
    @include bold;
    @include karla;

    @include breakpoint(0, 959px) {
      flex-grow: 1;
    }

    &-inner {
      display: inline-block;
      width: auto;
      text-align: center;
    }

    .block__date-day {
      font-size: 7rem;
      letter-spacing: 0;
      line-height: #{(50 / 70)};
      margin-bottom: #{(12 / 70)}em;
      font-family: $fontStack;
    }
  }

  .block__link {
    align-self: center;
    width: 100%;

    @include breakpoint(700px, 0) {
      flex-grow: 1;
      min-width: 300px;
      width: auto;
    }

    @include breakpoint(1400px, 0) {
      flex-grow: 0;
    }

    .block__link-element {
      display: block;
      width: 100%;
      padding: #{(11 / 16)}em #{(20 / 16)}em;
      font-size: 1.6rem;
      //   max-width: 250px;
      //   margin: 0 auto;
    }

    .block__link-fallback {
      @include bold;
      @include karla;
      font-size: 1.6rem;
      position: relative;
      text-align: center;
      border-radius: 55px;
      border: solid 2px;
      background: none;
      //   max-width: 250px;
      //   margin: 0 auto;

      display: block;
      width: 100%;
      background: #eeeeee;
      border-color: #eeeeee;
      color: rgba(92, 92, 92, 0.85);
      padding: #{(11 / 16)}em #{(20 / 16)}em;
      font-size: 1.6rem !important;

      @include hover {
        background: #eeeeee;
        border-color: #eeeeee;
        color: rgba(92, 92, 92, 0.85);
      }
    }
  }

  .block__meta {
    flex-grow: 1;

    @include breakpoint(0, 767px) {
      width: 100%;
    }

    .block__meta-item {
      display: flex;
      line-height: #{(26 / 18)};

      &:not(:last-child) {
        margin-bottom: 4px;
      }

      .block__meta-label {
        @include bold;
        @include karla;
        font-size: 1.6rem;
        width: 142px;
        padding-right: 5px;
      }

      .block__meta-value {
        width: calc(100% - 142px);
        font-size: 1.8rem;
        color: rgba($black, 0.85);
        letter-spacing: 0;

        a {
          color: #646465 !important;
          transition: border 200ms, color 200ms !important;
          border-bottom: 1px solid #646465 !important;

          @include hover {
            border-color: transparent !important;
            color: $blue !important;
          }
        }
      }
    }
  }

  .block__initial {
    .block__initial-wrap {
      background: #262626;
      color: white;
      font-size: 6rem;
      letter-spacing: 0;
      text-align: center;
      line-height: #{(40 / 60)};
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 700;
      text-transform: uppercase;
      width: #{(90 / 60)}em;
      height: #{(90 / 60)}em;

      &--logo {
        &:before {
          background-image: url(../img/logo-white.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center center;
          content: "";
          width: 1em;
          height: 1em;
        }
      }
    }
  }
}

.news-article__events {
  margin-top: 30px;
}

.news-article__event-list {
  margin-top: 40px;

  h2 {
    margin-bottom: 20px;
  }
}

// panels
// --------------------------------------------------
// .panel {

// }

.panel--breakout {
	position: relative;
	max-height: 85vh;
	min-height: 600px;

	&:before {
		display: block;
		content: '';
		width: 100%;
		padding-top: (45 / 79) * 100%;
	}

	.container {
		@include center;
		position: absolute;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		top: 0;
		height: 100%;
		z-index: 5;
	}

	.panel__content {
		width: 100%;
		max-width: 550px;
		color: #fff;
	}

	.panel__title {
		@include bold;
		@include karla;
		@include fluid(font-size, 700px, 1500px, 35px, 55px);
		margin: 0 0 35px 0;
	}

	.panel__copy {
		@include fluid(font-size, 700px, 1500px, 16px, 22px);
		margin: 0 0 35px 0;
	}

	// .panel__title,
	// .panel__copy,
	// .btn {
	// 	@include fluid(margin-left, 700px, 1500px, 40px, 100px);
	// }

	.panel__img {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
	}

	@include breakpoint(0, 900px) {
		.panel__img {
			@include overlay-custom(#000, 0.5);
		}
	}
}

.panel--promotion {
    margin-bottom: 145px;

	.home-grid > & {

		margin-bottom: 60px;
		margin-top: 20px;

	}

    .promotion {
        background-color: #030204;
    }

    img {
        display: block;
        width: 100%;
    }

    .promotion__img--desktop {

        @include breakpoint(0, 768px) {
		
            display: none;
		
        }
    }

    .promotion__img--mobile {
        display: none;

        @include breakpoint(0, 768px) {

            display: block;
        }
    }
}

.panel--logo-gallery {
	@include fluid(margin-bottom, 700px, 1500px, 60px, 160px);
	@include clearfix;
	border-bottom: 2px solid #000;
    border-top: 1px solid #000;

    .home & {
        margin-bottom: 0;
    }

	a {
		position: relative;
		display: inline-block;
		width: 16.667%;
		float: left;
		//box-shadow: inset -1px 0 0 0 rgba(0,0,0,1);
		transition: opacity 200ms;

		&:after {
			position: absolute;
			left: 3px;
			top: 0;
			width: 1px;
			height: 100%;
			background: rgba(#000, 0.3);
			content: '';
		}

		&:before {
			display: block;
		    content: '';
		    width: 100%;
			padding-top: (154 / 265) * 100%;
		}

		@include hover {
			opacity: 0.5;
		}
	}

	.logo-gallery__item-inner {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: 5;
		display: flex;
		text-align: center;
		align-items: center;
		justify-content: center;
		padding: 0 20px;
	}

	img {
		max-width: 150px;
        max-height: 70px;
		height: auto;
        image-rendering: -moz-crisp-edges;         /* Firefox */
        image-rendering:   -o-crisp-edges;         /* Opera */
        image-rendering: -webkit-optimize-contrast;/* Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor;  /* IE (non-standard property) */
	}

	@include breakpoint(0, 550px) {
		img {
			max-height: 50px;
			max-width: 120px;
		}
	}
}

.panel--subscribe {
	@include fluid(padding-top, 800px, 1500px, 60px, 100px);
	@include fluid(padding-bottom, 800px, 1500px, 60px, 100px);
	@include fluid(margin-bottom, 700px, 1500px, 60px, 160px);
	background: $black;
	color: #fff;

	.subscribe-form {
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 900px;
		margin: 0 auto;
	}

	label {
		@include bold;
		@include karla;
		@include fluid(font-size, 800px, 1500px, 22px, 30px);
		position: relative;
		width: 400px;

		span {
			@include serif;
			@include reg;
			@include fluid(font-size, 700px, 1500px, 16px, 20px);
			display: block;
		}

		&:not(.error) {
			&:after {
				@include icons;
				@include fluid(font-size, 700px, 1500px, 100px, 130px);
				position: absolute;
				color: rgba(#fff, 0.10);
				// content: "\E014";
				content: "";
				background-image: url(../img/logo-white.svg);
				opacity: .1;
				background-repeat: no-repeat;
				background-size: contain;
				background-position: 0 0;
				aspect-ratio: 727/877;
				width: 130px;
				top: 50%; 
				transform: translateY(-50%);
				left: -30%;
			}
		}
	}

	.subscribe-form__field {
		position: relative;
		width: calc(100% - 310px);
		padding-left: 8.5%;
		height: 65px;
	}

	input[type="email"] {
		@include font-size(16);
		@include karla;
		border-bottom: 1px solid #fff;
		height: 65px;
		background: none;
		color: #fff;
		padding: 0 115px 0 0;

		&::-webkit-input-placeholder {
			color: #fff;
			transition: color 200ms;
		}

		&:-moz-placeholder {
			color: #fff;
			transition: color 200ms;
		}

		&::-moz-placeholder {
			color: #fff;
			transition: color 200ms;
		}

		&:-ms-input-placeholder {
			color: #fff;
			transition: color 200ms;
		}

		&:focus::-webkit-input-placeholder {
			color: rgba(#fff, 0.5);
		}

		&:focus:-moz-placeholder {
			color: rgba(#fff, 0.5);
		}

		&:focus::-moz-placeholder {
			color: rgba(#fff, 0.5);
		}

		&:focus:-ms-input-placeholder {
			color: rgba(#fff, 0.5);
		}
	}

	button {
		@include bold;
		@include karla;
		position: absolute;
		right: 0;
		top: 18px;
		z-index: 5;
		background: none;

		span {
			position: relative;

			&:after {
				@include icons;
				content: "\E011";
				color: #fff;
				font-size: 20px;
				margin: 0 0 0 15px;
				transition: transform 200ms;
			}
		}

		@include hover {
			span:after {
				transform: translateX(3px);
			}
		}
	}

	@include breakpoint(0, 1150px) {
		label {
			&:after {
				left: 0;
			}
		}

		.subscribe-form__field {
			padding: 0;
		}
	}
	@include breakpoint(0, 700px) {
		.subscribe-form {
			display: block;
		}

		label {
			width: 100%;
			margin: 0 0 30px 0;
		}

		.subscribe-form__field {
			width: 100%;
		}
	}
}

// Buttons
// --------------------------------------------------

button {
	border: none;
	outline: none;
}

.button,
.btn {
	@include bold;
	@include fluid(font-size, 800px, 1500px, 16px, 20px);
	@include karla;
	position: relative;
	display: inline-block;	
	padding: 12px 40px;
	text-align: center;
	border-radius: 55px;
	border: solid 2px;
	transition: color 200ms, border 200ms, background 200ms;
	background: none; 

	&--white {
		color: #fff;
		border-color: #fff;

		@include hover {
			border-color: transparent;
			background: #fff;
			color: $black;
		}
	}

	&--blk {
		color: $black;
		border-color: $black;

		@include hover {
			border-color: transparent;
			background: $black;
			color: #fff;
		}
	}

	&--small {
		@include fluid(font-size, 800px, 1500px, 14px, 14px);
		padding: 9px 30px;
	}

	&:focus {
		top: 1px;
	}

	&--browse {
		padding-left: 40px;

		&:before {
			@include icons;
			position: absolute;
			font-size: 17px;
			content: "\E004";
			left: 13px;
			top: 10px;
		}
	}

	&--watch {
		padding: 7px 18px 6px 35px;

		&:before {
			@include icons;
			position: absolute;
			font-size: 17px;
			content: "\E002";
			left: 10px;
			top: 7px;
		}
	}

	&--scroll {
		padding-right: 75px;
		&:after,
		&:before {
			display: block;
			content: ' ';
			position: absolute;
			transition: all 0.2s linear;
		}
		&:before {
			top: 17px;
			right: 41px;
			width: 2px;
			height: 14px;
			background: #fff;
			background: currentColor;
		}
		&:after {
			top: 22px;
			right: 37px;
			width: 10px;
			height: 10px;
			border: 2px solid #fff;
			border-color: currentColor;
			border-width: 0 2px 2px 0;
			transform: rotate(45deg);
		}
		@include hover() {
			&:before { 
				background: $black;
				background: currentColor;
			}
			&:after { 
				border-color: $black; 
				border-color: currentColor;
			}
		}
		@include breakpoint(0, 1023px) {
			padding-right: 60px;
			&:before {
				top: 18px;
				right: 33px;
				height: 10px;
			}
			&:after {
				top: 20px;
				right: 30px;
				width: 8px;
				height: 8px;
			}
		}
	}
	

}

// Links

.link--search {
	position: relative;
	background: none;
	border: none;
	
	&:after {
		@include icons;
		font-size: 12px;
		color: #fff;
		margin: -3px 0 0 12px;
		content: "\E001";
	}

	&:focus {
		&:before {
			content: none !important;	
		}
	}

	.search-open & {
		&:after {
			//content: "\E008";
		}
	}
}

.link--search-alt {
	width: 60px;
	height: 60px;
	text-align: center;
	background: none;
	padding: 0;
	
	&:before {
		@include icons;
		position: relative;
		font-size: 20px;
		color: #fff;
		content: "\E001";
		top: -3px;
	}

	.search-open & {
		&:before {
			//content: "\E008";
		}
	}
}

.link--subscribe {
	@include bold;
	@include font-size(14);
	@include karla;
	color: rgba(#fff, 0.3);
	transition: color 200ms;

	@include hover {
		color: #fff;
	}

	@include breakpoint(0, 800px) {
		.mob-hide {
			@include hide;
		}
	}
}

.link--close {
	@include bold;
	@include karla;
	@include font-size(12);
	color: rgba(#fff, 0.5);
	background: none;
	line-height: 1.1;
}

.link--back-to {
	@include karla;
	@include bold;
	@include fluid(font-size, 700px, 1500px, 14px, 16px);
	display: block;
	color: $black;
	margin: 0 0 10px 0;
	border: 0;

	@include hover {
		color: rgba($black, 0.5);
	}

}

.link--next {
	@include karla;
	@include bold;
	@include fluid(font-size, 700px, 1500px, 15px, 20px);
	display: inline-block;
	text-align: right;
	color: $black;
	
	em {
		display: inline-block;
		color: $blue;

		span {
			@include fluid(font-size, 600px, 1500px, 15px, 20px);
			color: #dedede;
			display: inline-block;
			width: auto;
		}
	}

	&-direction {
		@include fluid(font-size, 700px, 1500px, 13px, 16px);
		display: block;
	}

	@include hover {
		color: $blue;
	}
}

.link--email {
	@include karla;
	@include bold;
	@include fluid(font-size, 700px, 1500px, 15px, 20px);
	color: $black;

	&:before {
		@include icons;
		content: "\E012";
		font-size: 16px;
		margin: 0 15px 0 0;
	}

	@include hover {
		color: rgba($black, 0.5);
	}
}
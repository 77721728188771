

.gfield {

    border: none;
    grid-column: 1 / 3;
    padding: 0;
    margin: 0;

    &--width-half {

        @include breakpoint(1024px) {

            grid-column: auto / span 1;

        }

    }

}

.gform_fields {

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

}

.gform-grid-col,
.ginput_container {

    position: relative;

}

.ginput_complex {

    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

}

.hidden_label > label,
.gfield_required {

    @include hide;

}

.gform_footer {

    margin-top: 20px;
    display: flex;
    justify-content: flex-end;

}

.gform_body,
.gform_footer {

    width: 100%;

}

.gform_button {

    width: auto;

    @include hover {
        border-color: transparent;
        background: $black;
        color: #fff;
    }

}


/**
 File Uploads
 */

 .gform_wrapper.gravity-theme .gform_drop_area {
    padding: 2em;
    height: auto;
    text-align: center;
}

.gform_wrapper.gravity-theme .gform_drop_instructions {
    display: block;
}

.gform_wrapper.gravity-theme .gform_delete, .gform_wrapper.gravity-theme .gform_delete_file {
    cursor: pointer;
    vertical-align: middle;
}

.gform_wrapper.gravity-theme .gform_delete_file {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    border: none;
    margin: 0;
    padding: 0 0 0 1em;
}

.gform_wrapper.gravity-theme .gfield_fileupload_filesize {
    padding: 0px 6px;
}

.gform_wrapper.gravity-theme .ginput_preview {
    margin: 1rem 0;
}

.gform_wrapper.gravity-theme .gform_fileupload_rules {
    display: block;
    margin-top: 8px;
}


.gfield .validation_message--hidden-on-empty {

    list-style: none;
    margin: 1em 0;
    display: flex;
    flex-direction: column;
    gap: .5em;

    &:empty {

        display: none;

    }

    > li.gfield_validation_message {

        padding: 0;
        margin: 0;

    }

}
// Modals
// --------------------------------------------------

.modal-video {
	display: none;
}

.modal-video-wrapper {
	background: $black;
	color: #fff;
	
	.modaal-container {
		background: $black;
	}
	
	.modaal-content-container {
		padding: 0;
	}
	
	&__video {
		position: relative;
		padding-bottom: 56.25%;
		height: 0;
		margin: 0 0 30px 0;
		
		iframe {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}
	
	&__content {
		
	}
	
	h2 {
		@include fluid(font-size, 500px, 1400px, 22px, 30px);
		@include bold;
		color: #fff;
		margin: 0 0 5px 0;
	}
	
	span {
		@include font-size(16);
		@include bold;
		@include karla;
		display: inline-block;
		margin: 0 0 20px 0;
		color: #fff;
	}
	
	p {
		@include fluid(font-size, 500px, 1400px, 16px, 18px);
		@include serif;
		color: #fff;
	}
	
}

#modal-slider-gallery {
	display: none;
}

.modal-slider-gallery-wrapper,
.modal-home-gallery-wrapper {
	background: rgba(#041021, 0.9);
	
	.modaal-container {
		background: rgba(#041021, 0.9);
	}
	
	.modaal-content-container {
		@include center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		padding: 0;
		max-width: 1200px;
		width: 100%;
	}
	
	&__close {
		@include bold;
		position: absolute;
		top: -35px;
		right: 0;
		color: #fff;
		
		&:before {
			@include icons;
			font-size: 12px;
			color: rgba(#fff, 0.5);
			margin: -3px 5px 0 0;
			content: "\E00D";
			transition: color 200ms;
		}
		
		@include hover {
			color: #fff;
			
			&:before {
				color: #fff;
			}
		}		
	}
	
	.slider__item {
		position: relative;
		display: inline-block;
		width: 100%;
		float: left;
		
		&:before {
			display: block;
		    content: '';
		    width: 100%;
		    padding-top: 56.25%;	
		}
	}
	
	.inner {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		border-radius: 5px;
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;		
	}
	
	&__nav {
		position: absolute;
		top: 50%;
		left: 0;
		width: 100%;
		z-index: 10;
	}
	
	.slick-arrow {
		position: absolute;
		height: 20px;
		width: 20px;
		cursor: pointer;
				
		&:before {
			@include icons;
			position: absolute;	
			font-size: 20px;
			color: #fff;
			width: 100%;
			text-align: center;
			transition: opacity 200ms;
		}
		
		@include hover {
			&:before {
				opacity: 0.6;
			}
		}
	}
	
	.prev {
		left: -40px;
		
		&:before {
			content: "\E008";	
		}
	}
	
	.next {
		right: -40px;
		
		&:before {
			content: "\E009";	
		}
	}
	
	@include breakpoint(0, 1300px) {
		.modaal-content-container {
			padding: 0 40px;
		}
		
		&__close {
			right: 40px;
		}
		
		.prev {
			left: 10px;
		}
		
		.next {
			right: 10px;
		}
	}
	
}
// Page Article
// --------------------------------------------------
.page-template-template-payment-form,
.page--standard {
    main.content {
        padding-top: 0 !important;
    }

    .panel--logo-gallery {
        border-top: 2px solid #000;
        border-bottom: 0;
        margin-bottom: 0 !important;
        margin-top: 160px;
    }
    
    .page-content__content-inner {
        margin-top: 0 !important;
    }
}
// Clearing
// --------------------------------------------------

.container:after { 
	clear: both;
    content: '';
    display: table; 
}

.clearfix:after,
.row:after {
    clear: both;
    content: '';
    display: table;
}

.clear {
  clear: both;
  display: block;
  overflow: hidden;
  visibility: hidden;
  width: 0;
  height: 0;
}

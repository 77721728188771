// Type defaults
// --------------------------------------------------

$fontStack: 'Crimson Text', Georgia, Times, "Times New Roman", serif;
$fontStack-alt: 'Karla', "Helvetica Neue", Helvetica, Arial, sans-serif;

@mixin light() { font-weight: 300; }
@mixin reg() { font-weight: 400; }
@mixin bold() { font-weight: 700; }

@mixin karla {
	font-family: $fontStack-alt;
}

@mixin serif {
	font-family: $fontStack;
}


// Type Scale
// --------------------------------------------------

html {
	font-size: 6.25%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
}

html, body {
	text-rendering: geometricPrecision;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}


// Headings and paragraphs
// --------------------------------------------------

h1, h2, h3, h4, h5, h6 { font-family: $fontStack-alt; font-weight: normal; line-height: 1.1; margin: 0; @include bold}
h1 a, h2 a, h3 a, h4 a, h5 a, h6 a { font-weight: inherit; }


// General type
// --------------------------------------------------

p {
	margin: 0;
	line-height: 1.45;

	+ p {
		margin: 25px 0 0;
	}
}

strong {
	@include bold;
}

// Links
// --------------------------------------------------

a {
	text-decoration: none;
	outline: 0;
	transition: color $timing linear;

	&:hover,
	&:focus {

	}
}

em {
	font-style: normal;
}


// Colours
.text {
	&-black { color: $black; }
	&-gold { color: $gold; }
}
@font-face {
	font-family: "Icons";
	src: url('fonts/Icons.eot');
	src: url('fonts/Icons.eot?#iefix') format('eot'),
		url('fonts/Icons.woff2') format('woff2'),
		url('fonts/Icons.woff') format('woff'),
		url('fonts/Icons.ttf') format('truetype'),
		url('fonts/Icons.svg#Icons') format('svg');
}

.icon:before {
	font-family: "Icons";
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	/* speak: none; only necessary if not using the private unicode range (firstGlyph option) */
	text-decoration: none;
	text-transform: none;
}


.icon-icn_01search:before {
	content: "\E001";
}

.icon-icn_02play:before {
	content: "\E002";
}

.icon-icn_03arrow:before {
	content: "\E003";
}

.icon-icn_04facebook:before {
	content: "\E004";
}

.icon-icn_05insta:before {
	content: "\E005";
}

.icon-icn_06logo:before {
	content: "\E006";
}

.icon-icn_07arrowDown:before {
	content: "\E007";
}

.icon-icn_08close:before {
	content: "\E008";
}

.icon-icn_09crown:before {
	content: "\E009";
}

.icon-icn_10left:before {
	content: "\E00A";
}

.icon-icn_11right:before {
	content: "\E00B";
}

.icon-icn_12star:before {
	content: "\E00C";
}

.icon-icn_13halfstar:before {
	content: "\E00D";
}

.icon-icn_14dollar:before {
	content: "\E00E";
}

.icon-icn_15twitter:before {
	content: "\E00F";
}

.icon-icn_16mail:before {
	content: "\E010";
}

.icon-icn_17arrow:before {
	content: "\E011";
}

.icon-icn_18mail:before {
	content: "\E012";
}

.icon-icn_19arrowUpRight:before {
	content: "\E013";
}

.icon-icn_20logo-2:before {
	content: "\E014";
}

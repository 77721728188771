// REM font size with px fallback
@mixin font-size($sizeValue: 1.6) {
	font-size: ($sizeValue * 1) + px;
	font-size: ($sizeValue / 10) + rem;
}


// Reseting list menus
@mixin list-reset() {
	list-style: none;
	padding: 0;
	margin: 0;
	
	li {
		margin: 0;
		padding: 0;
	}
}

@mixin ratio($ratio) {
	display: block;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	
	&:before {
		content: '';
		width: 100%;
		display: block;
		padding-top: $ratio;
	}
}


// hover helper
@mixin hover() {
	html:not(.mobile):not(.tablet) &:hover,
	html:not(.mobile):not(.tablet) &:focus,
    &.on_press {
        @content;
    }
}

@mixin valign {
	display: flex;
    flex-direction: column;
    justify-content: center;
}

@mixin center {
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

@mixin clearfix {
  &:after {
    clear: both;
    content: '';
    display: table;
  }
}

// usage: @extend %clearfix;

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

// grids!
@mixin grid($colCount, $gutterSize: 0px, $selector: '.col') {
	@if $gutterSize != '0px' or $gutterSize != '0' or $gutterSize != 0px or $gutterSize != 0 {
		margin-left: -$gutterSize;
	}
	&:after {
		@include clearfix;
	}
		
	#{$selector} {
		float: left;
		padding-left: $gutterSize;
		width: (100/$colCount) * 1%;
		&:nth-child(#{$colCount}n+1 ) {
			clear: left;
		}
	}
	
	@if $colCount == 1 {
		margin-left: 0;
		
		#{$selector} {
			padding-left: 0;
		}
	}
}

// flex box grids!

@mixin flex-grid($columnCount, $gutterSize: 0px, $bottomMargin: 0px, $selector: '.col') {
	display: -ms-flexbox;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	justify-content: flex-start;
	
	@if $gutterSize != '0px' or $gutterSize != '0' or $gutterSize != 0px or $gutterSize != 0 {
		margin-left: -$gutterSize;
	}
		
	#{$selector} {
		padding-left: $gutterSize;
		width: (100/$columnCount) * 1%;
		@if $bottomMargin != '0px' or $bottomMargin != 0px {
			margin-bottom: $bottomMargin;
		}
	}
	
	@if $columnCount == 1 {
		margin-left: 0;
		
		#{$selector} {
			padding-left: 0;
		}
	}
}

// Custom flexbox setup
// wrap - nowrap

@mixin flex-columns($isWrap) {
	display: flex;
	flex-wrap: $isWrap;
	flex-direction: row;
	justify-content: flex-start;
}


// breakpoints
@mixin breakpoint($min: 0, $max: 0, $side: 'width') {
    // check to see if first variable is string or number
    $type: type-of($min);
    $sideType: type-of($side);
        
    // if it's a number, check if min, max or both are required.
    // then check if the values of $side are correctly spelt 'width' or 'height'
    // write new breakpoint using these boundaries.
    // default to 'all' if nothing specified.
    @if $type == number and $sideType == string {
        @if $side == 'width' or $side == 'height' {
            $query: "all" !default;
            @if      $min != 0 and $max != 0 { $query: "(min-#{$side}: #{$min}) and (max-#{$side}: #{$max})"; } // set both min and max
            @else if $min != 0 and $max == 0 { $query: "(min-#{$side}: #{$min})"; } // set just min
            @else if $min == 0 and $max != 0 { $query: "(max-#{$side}: #{$max})"; } // set just max
            @media #{$query} { @content; }
        }
    }
}

@mixin icons() {
	font-family: "Icons";
	display: inline-block;
	vertical-align: middle;
	line-height: 1;
	font-weight: normal;
	font-style: normal;
	speak: none;
	text-decoration: inherit;
	text-transform: none;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin hide {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px !important;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
}


// where null = delay (if required)
// This needs ot be expanded ot include multiple transitions on one object
// Next step below?
// https://coderwall.com/p/qviyew/sass-compass-manytransitions-mixin
// 

/*
@mixin transition($trans-type, $trans-speed, $trans-timing, null) {
	transition: $trans-type $trans-speed $trans-timing;
	will-change: $trans-type;
}
*/

@mixin trans-bg {
	transition: background 200ms ease;
}

@mixin trans-color {
	transition: color 200ms ease;
}

@mixin trans-padding {
	transition: padding 200ms ease;
}

@mixin trans-opacity {
	transition: opacity 200ms ease;
}

@mixin trans-transform {
	transition: transform 200ms ease-in-out;
}

@mixin trans-border {
	transition: border-color 200ms ease-in-out;
}

@mixin trans-all {
	transition: all 200ms ease;
}

@mixin image-2x($image, $width, $height) {
  @media (min--moz-device-pixel-ratio: 1.3),
         (-o-min-device-pixel-ratio: 2.6/2),
         (-webkit-min-device-pixel-ratio: 1.3),
         (min-device-pixel-ratio: 1.3),
         (min-resolution: 1.3dppx) {
    /* on retina, use image that's scaled by 2 */
    background-image: url($image);
    background-size: $width $height;
  }
}

@mixin overlay-custom($color, $opacity) {
	
	&:before {
		@include trans-bg;
		position: absolute;
		width: 100%;
		height: 100%;
		background: rgba($color, $opacity);
		top: 0;
		left: 0;
		content: '';
	}
}

@mixin cover {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
}

@mixin float-l {
	display: inline-block;
	float: left;
}

@mixin float-r {
	display: inline-block;
	float: right;
}


// responsive type
@mixin fluid($properties, $min-vw, $max-vw, $min-value, $max-value) {
    @each $property in $properties {
        #{$property}: $min-value;
    }
    
    @media screen and (min-width: $min-vw) {
        @each $property in $properties {
            #{$property}: calc(#{$min-value} + #{strip-unit($max-value - $min-value)} * (100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)});
        }
    }
    
    @media screen and (min-width: $max-vw) {
        @each $property in $properties {
            #{$property}: $max-value;
        }
    }
}

@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

//use

/*
	
	@include fluid(font-size, 768px, 1310px, 14px, 18px);
	
*/

/*
* Dotted Border - A SASS mixin to help you increase spacing between the dots of border-style:dotted.
* By @florbraz
* Documentation and examples - github.com/florbraz/Dotted-Border-w-custom-spacing-SCSS-Mixin
* V1.0
*/
@mixin dottedBorder($color: #8f8f8f, $orientation: horizontal, $position: top, $spacing: 5px, $size: 1px) {
	background-position: $position;
	@if $orientation == horizontal {
	  background-image: linear-gradient(to right, $color $size/$spacing * 100%, rgba(255,255,255,0) 0%);
	  background-size: $spacing $size;
	  background-repeat: repeat-x;
	}
	@else {
	  background-image: linear-gradient($color $size/$spacing * 100%, rgba(255,255,255,0) 0%);
	  background-size: $size $spacing;
	  background-repeat: repeat-y;
	}
	.ie9 & {
	  border-#{$position}:1px dotted $color;      
	}
  }

  // @include dottedBorder($color: #515151, $orientation: horizontal, $position: bottom, $spacing: 5px, $size: 1px);

// Page Wine
// --------------------------------------------------

.page--wine-awards {
    
    .panel--awards-featured {
        @include fluid(padding-bottom, 700px, 1500px, 60px, 100px);
        @include fluid(margin-top, 700px, 1500px, -60px, -160px);
        position: relative;
        z-index: 5;
    }
    .top50-grid {
        @include fluid(padding-bottom, 700px, 1500px, 60px, 140px);
    }

    .wine-awards__ad {
        display: none;
        width: 100%;
        &--desktop {
            @include breakpoint(1251px, 0) {
                display: block;
                //margin: 50px 0;
                padding: 40px 0 100px 60px;
            }
            .panel--promotion {
                margin: 0;
                position: relative;
                padding-top: (17 / 134) * 100%;
                .promotion {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                }
            }
        }
        &--mobile {
            @include breakpoint(0, 1250px) {
                display: block;
            }
        }
        &--always {
            display: block;
        }
    }
}

.wine-profile {

    @include fluid(padding-bottom, 700px, 1500px, 60px, 160px);
    position: relative;

    &__content {
        display: flex;
        justify-content: space-between;
        @include breakpoint(0, 580px) {
            flex-direction: column-reverse;
        }
    }

    &__content-inner {
        flex-basis: auto;
        background: #fff;
        position: relative;
        z-index: 10;

        @include breakpoint(581px, 0) {
            width: 75%;
            padding: 0 8.5% 0 0;
        }
    }

    &__copy {
        p {
            @include fluid(font-size, 700px, 1500px, 16px, 22px);
        }

        ul {
            @include fluid(font-size, 700px, 1500px, 16px, 22px);
        }

        & + .btn {
            margin: 35px 0 0 0;
        }
    }

    &__img {
        img {
            display: block;
            width: auto;
            max-height: 760px;
            height: auto;
            @include breakpoint(0, 1024px) {
                max-height: 450px;
            }
            @include breakpoint(0, 480px) {
                max-height: 300px;
            }
        }
        @include breakpoint(0, 580px) {
            padding: 20px 0 40px;
        }
        @include breakpoint(0, 480px) {
            padding: 20px 0 30px;
        }
    }

    .restaurant-profile__meta {
        @include fluid(padding-top, 700px, 1500px, 40px, 50px);
        width: 100%;
        max-width: 450px;
    }

}
// Brand Colors
// --------------------------------------------------

$black:         #1a1a1d;
$gold:          #ceb04c;
$blue:          $gold;



// Starter Colors
// --------------------------------------------------

$bgColor:		#fff; // Background Color
$bgMobColor:	#2b2b2b; // Background of the mobile menu
$color_1: 		#27282d; // Primary Color
$color_2: 		#666; // Secondary Color
$buttonColor:	#333; // Button background color
$borderColor:	#ebebeb; // Default border color
$error:			#d03838; // Error


// Social colors
// --------------------------------------------------

$facebook:			#3b5999;
$twitter:			#44acee;
$instagram:			#203344;
$google:			#dd4b39;


// Timing & Easing
// --------------------------------------------------

$timing:			.2s;
$waypoint-duration: 0.8s;

$ease-std:			ease-in-out;
$ease-pop:			cubic-bezier(0.68, -0.55, 0.265, 1.55);
$ease-pop-big:		cubic-bezier(0.3, 0, 0, 1.3);

// Cubic
$ease-cubic-i:		cubic-bezier(0.55, 0.055, 0.675, 0.19);
$ease-cubic-o:		cubic-bezier(0.215, 0.61, 0.355, 1);

// Circ
$ease-circ-i:		cubic-bezier(0.6, 0.04, 0.98, 0.335);
$ease-circ-o:		cubic-bezier(0.075, 0.82, 0.165, 1);

// Expo
$ease-expo-i:		cubic-bezier(0.95, 0.05, 0.795, 0.035);
$ease-expo-o:		cubic-bezier(0.19, 1, 0.22, 1);

// Quad
$ease-quad-i:		cubic-bezier(0.55, 0.085, 0.68, 0.53);
$ease-quad-o:		cubic-bezier(0.25, 0.46, 0.45, 0.94);

// Quart
$ease-quart-i:		cubic-bezier(0.895, 0.03, 0.685, 0.22);
$ease-quart-o:		cubic-bezier(0.165, 0.84, 0.44, 1);


// Responsive Breakpoints
// --------------------------------------------------

$bp-x-large:			1600px;
$bp-max:				1440px;
$bp-container:			1340px;
$bp-large:				1200px;
$bp-lower-large:		1150px;
$bp-mid:				1023px;
$bp-semi-mid:			960px;
$bp-lower-mid:			830px;
$bp-small:				767px;
$bp-lower-small:		700px;
$bp-xl-small:			650px;
$bp-x-small:			600px;
$bp-xx-small:			550px;
$bp-handheld:			480px;
$bp-lower-handheld:		400px;
$bp-super-small:		350px;


// Z-Index Scale - If you're still wanting to use the system
// --------------------------------------------------

$z1:	10;
$z2:	20;
$z3:	30;
$z4:	40;
$z5:	50;
$z6:	60;
$z7:	70;
$z8:	80;
$z9:	90;
$z10:	100;